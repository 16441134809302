import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { toast, ToastContainer } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';  
import { useEvent } from '../../contexts/EventContext';  
import axiosInstance from "../../axiosInstance";
import Swal from 'sweetalert2';
import OrgTopBar from '../OrgTopBar';


const CreateSession = ({ handleClose = () => {} }) => { // Default value for handleClose
  const { eventId } = useEvent();
  const [eventStartDate, setEventStartDate] = useState(null);
  const [eventEndDate, setEventEndDate] = useState(null);
  const [dates, setDates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedDate, setSelectedDate] = useState('');
  const [sessionName, setSessionName] = useState(''); // Add state for sessionName

  useEffect(() => {
    const fetchEventDates = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get(`/event_dates_by_eventID/${eventId}`);
        const { event_start_date, event_end_date } = response.data;
        setEventStartDate(event_start_date);
        setEventEndDate(event_end_date);

        // Generate dates between start and end date
        if (event_start_date && event_end_date) {
          const startDate = new Date(event_start_date);
          const endDate = new Date(event_end_date);

          const generatedDates = [];
          for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
            generatedDates.push(new Date(date).toISOString().split('T')[0]); // Format: YYYY-MM-DD
          }

          setDates(generatedDates);
        }
      } catch (error) {
        setError('Error fetching event dates.');
        console.error('Error fetching event dates:', error);
      } finally {
        setLoading(false);
      }
    };

    if (eventId) {
      fetchEventDates();
    }
  }, [eventId]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
  };

 

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      // Make the API request to create a new session
      const response = await axiosInstance.post('/create_session_by_eventId', {
        event_unique_id: eventId,
        session_date: selectedDate,
        session_name: sessionName
      });
  
      // Check for a successful response
      if (response.status === 201) {
        // Show SweetAlert2 success message with confirmation button
        Swal.fire({
          title: 'Success!',
          text: 'Session created successfully!',
          icon: 'success',
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) {
            // Redirect after confirmation
            window.location.href = `/events/${eventId}/session-and-agenda`;
          }
        });
        handleClose(); // Close the form or do any other necessary action
      }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        // Handle the case where the session already exists
        Swal.fire({
          title: 'Error',
          text: 'Session already exists.',
          icon: 'error',
          confirmButtonText: 'OK'
        });
      } else {
        // Handle other errors
        Swal.fire({
          title: 'Error',
          text: 'Error creating session.',
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
      console.error('Error creating session:', error);
    }
  };
  
  

  return (
    <div className="container-dash p-0 position-relative">

<OrgTopBar/>


      <div className="background-overlay"></div>
      <div className="d-flex justify-content-center align-items-center">
        <div className="col-md-12 d-flex align-items-center justify-content-center">
          <div className="container my-3">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12 col-sm-12">

              <div class="headingcard align-content-center gradient-border py-1 mb-2">
                  <h1 class="text-start text-white px-3 titleh1">
                  Create Session
                  </h1>
                  <p class="text-start text-white px-3 pb-0 ">
                    Create Session For Event
                  </p>
                </div>


                <div className="card shadow-lg rounded">
                  <div className="card-body p-3">
                    <form className="text-start" onSubmit={handleSubmit}>
                      <div className="mb-3">
                         

                        

                        {/* Dropdown for selecting session date */}
                        <div className="row mb-3">
                          <div className="col-md-3 text-end">
                            <label className="form-label lable-cl">Select Session Date</label>
                          </div>
                          <div className="col-md-9">
                            <select
                              className="form-control"
                              value={selectedDate}
                              onChange={(e) => setSelectedDate(e.target.value)}
                              required
                            >
                              <option value="">Select Event Date</option>
                              {dates.map(date => (
                                <option key={date} value={date}>
                                  {formatDate(date)}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-md-3 text-end">
                            <label className="form-label lable-cl">Session Name</label>
                          </div>
                          <div className="col-md-9">
                            <input
                              type="text"
                              id="event_partner_name"
                              name="event_partner_name"
                              className="form-control"
                              placeholder="Enter Session Name"
                              value={sessionName}
                              onChange={(e) => setSessionName(e.target.value)} // Update state on input change
                              required
                            />
                          </div>
                        </div>

                        <div className="text-center">
                          <button type="submit" className="btn btnn org-btn text-light w-25">Create Session</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default CreateSession;
