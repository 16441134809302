import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import FirstSidebarOrganiser from "../Component/FirstSidebarOrganiser";
import BASE_URL from "../Component/Baseurl";

const UserAccessAdd = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    countryCode: "+1", // Default to US
    selectedEvents: [], // For storing selected event IDs
  });

  const [errors, setErrors] = useState({
    email: "",
    phone: "",
  });

  const [events, setEvents] = useState([]); // For storing the fetched events

  const countryCodes = [
    { code: "+1", country: "US" },
    { code: "+91", country: "India" },
    { code: "+44", country: "UK" },
    // Add more country codes as needed
  ];

  useEffect(() => {
    const organiserId = localStorage.getItem("organiser_id");

    console.log("Organiser ID:", organiserId);

    // Fetch events for the organiser
    const fetchEvents = async () => {
      try {
        const response = await axios.post(`${BASE_URL}/events`, {
          organiserId,
        });
        console.log("API Response:", response);

        setEvents(response.data || []); // Set events to the fetched data
        console.log("Fetched Events:", response.data);
      } catch (error) {
        console.error("Error fetching events:", error);
        toast.error("Failed to fetch events");
      }
    };

    fetchEvents();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (eventId) => {
    const isSelected = formData.selectedEvents.includes(eventId);
    if (isSelected) {
      setFormData({
        ...formData,
        selectedEvents: formData.selectedEvents.filter((id) => id !== eventId),
      });
    } else {
      setFormData({
        ...formData,
        selectedEvents: [...formData.selectedEvents, eventId],
      });
    }
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validatePhone = (phone) => {
    const regex = /^[0-9]{10}$/; // Validates a 10-digit phone number
    return regex.test(phone);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    let emailError = "";
    let phoneError = "";
  
    // Validate email
    if (!validateEmail(formData.email)) {
      emailError = "Please enter a valid email address.";
    }
  
    // Validate phone
    if (!validatePhone(formData.phone)) {
      phoneError = "Please enter a valid 10-digit phone number.";
    }
  
    if (emailError || phoneError) {
      setErrors({ email: emailError, phone: phoneError });
      toast.error("Please fix the errors before submitting.");
      return;
    }
  
    // Get organiser ID from local storage
    const organiserId = localStorage.getItem("organiser_id");
  
    // If everything is valid, submit the data
    try {
      const response = await axios.post(`${BASE_URL}/user-access`, {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        selectedEvents: formData.selectedEvents,
        organiserId, // Include organiser ID
      });
  
      toast.success("User access created successfully!");
      console.log("User Access Response:", response.data);
      
      // Optionally reset the form or handle further actions here
      setFormData({
        name: "",
        email: "",
        phone: "",
        countryCode: "+1",
        selectedEvents: [],
      });
    } catch (error) {
      console.error("Error submitting user access:", error);
      toast.error("Failed to create user access.");
    }
  };
  

  return (
    <>
      <ToastContainer />
      <FirstSidebarOrganiser />
      <div className="container-dash p-0 position-relative">
        <div className="background-overlay"></div>
        <div className="d-flex justify-content-center align-items-center">
          <div className="col-md-12 d-flex align-items-center justify-content-center">
            <div className="container my-3">
              <div className="row justify-content-center">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="card shadow-lg rounded">
                    <div className="card-body p-4">
                      <div className="d-flex justify-content-between align-items-center mb-4">
                        <h3 className="text-start h3txt">Add User Details</h3>
                      </div>

                      <form className="text-start" onSubmit={handleSubmit}>
                        <div className="mb-3">
                          <div className="row">
                            <div className="col-md-3 text-end">
                              <label htmlFor="name" className="form-label lable-cl">
                                Name
                              </label>
                            </div>
                            <div className="col-md-9">
                              <input
                                type="text"
                                id="name"
                                name="name"
                                className="form-control"
                                placeholder="Enter your name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                              />
                            </div>
                          </div>
                        </div>

                        <div className="mb-3">
                          <div className="row">
                            <div className="col-md-3 text-end">
                              <label htmlFor="email" className="form-label lable-cl">
                                Email
                              </label>
                            </div>
                            <div className="col-md-9">
                              <input
                                type="email"
                                id="email"
                                name="email"
                                className="form-control"
                                placeholder="Enter email address"
                                value={formData.email}
                                onChange={handleChange}
                                required
                              />
                              {errors.email && <small className="text-danger">{errors.email}</small>}
                            </div>
                          </div>
                        </div>

                        <div className="mb-3">
                          <div className="row">
                            <div className="col-md-3 text-end">
                              <label htmlFor="phone" className="form-label lable-cl">
                                Phone
                              </label>
                            </div>
                            <div className="col-md-9 d-flex">
                              <select
                                className="form-select w-auto me-2"
                                name="countryCode"
                                value={formData.countryCode}
                                onChange={handleChange}
                              >
                                {countryCodes.map((code) => (
                                  <option key={code.code} value={code.code}>
                                    {code.country} ({code.code})
                                  </option>
                                ))}
                              </select>

                              <input
                                type="text"
                                id="phone"
                                name="phone"
                                className="form-control"
                                placeholder="Enter 10-digit phone number"
                                value={formData.phone}
                                onChange={handleChange}
                                required
                              />
                              {errors.phone && <small className="text-danger">{errors.phone}</small>}
                            </div>
                          </div>
                        </div>

                        {/* Events List with checkboxes */}
                        <div className="mb-3">
                          <div className="row">
                            <div className="col-md-3 text-end">
                              <label className="form-label lable-cl">Events List</label>
                            </div>
                            <div className="col-md-9">
                              {Array.isArray(events) && events.length > 0 ? (
                                events.map((event) => (
                                  <div key={event.id}>
                                    <input
                                      type="checkbox"
                                      id={`event-${event.id}`}
                                      name="selectedEvents"
                                      value={event.id}
                                      checked={formData.selectedEvents.includes(event.id)}
                                      onChange={() => handleCheckboxChange(event.id)}
                                    />
                                    <label htmlFor={`event-${event.id}`}>{event.event_name}</label>
                                  </div>
                                ))
                              ) : (
                                <p>No events available</p>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="mb-3 text-center">
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserAccessAdd;
