import React, { useState, useEffect } from "react";
import axiosInstance from "../../axiosInstance";
import { useEvent } from "../../contexts/EventContext";
import { Link, useParams, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons"; // Import edit and trash icons
import OrgTopBar from "../OrgTopBar";

const SessionAgenda = () => {
  const { eventId } = useEvent();
  const [sessions, setSessions] = useState([]);
  const [formError, setFormError] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const { event_unique_id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSessions = async () => {
      try {
        const response = await axiosInstance.get(
          `/fetch_sessions_by_eventID/${event_unique_id}`
        );
        setSessions(response.data.sessions);
      } catch (error) {
        console.error("Error fetching sessions:", error);
        setFormError("Error fetching sessions. Please try again.");
      }
    };

    if (event_unique_id) {
      fetchSessions();
    } else {
      console.error("Invalid event_unique_id");
    }
  }, [event_unique_id]);

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axiosInstance.delete(`/sessions_delete/${id}`);
          setSessions(sessions.filter((session) => session.id !== id));
          toast.success("Session Deleted Successfully!");
        } catch (error) {
          console.error("Error deleting session:", error);
          setFormError("Error deleting the session. Please try again.");
        }
      }
    });
  };

  const handleEdit = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to edit this session?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, edit it!",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate(`/events/${event_unique_id}/update-agenda/${id}`);
      }
    });
  };

  // Filter sessions based on the search term
  const filteredSessions = sessions.filter((session) =>
    session.session_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="container-dash p-0 position-relative">
      <OrgTopBar/>
      <ToastContainer />
      <div className="background-overlay"></div>
      <div className="d-flex justify-content-center align-items-center">
        <div className="col-md-12 d-flex align-items-center justify-content-center">
          <div className="container my-1">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12 col-sm-12 mt-3">


              <div class="headingcard align-content-center gradient-border py-1 mb-2">
                  <h1 class="text-start text-white px-3 titleh1">
                  Session & Agenda
                  </h1>
                  <p class="text-start text-white px-3 pb-0 ">
                    Below Is the list of created Session List.
                  </p>
                </div>


                <div className="card shadow-lg rounded">
                  <div className="card-body p-3">
                    <div className=" text-end mb-2">
                  
                      <Link
                        to={`/events/${event_unique_id}/add-session`}
                        className="text-light shotbtn p-2 bg-gradient-info"
                      >
                        Add Session
                      </Link>
                    </div>

                    {/* Search input field */}
                    <div className="col-md-12 mt-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search by Session Name..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>

                    <div className="row text-start ft-head-table py-2 mt-2 mb-2">
                      <div className="col">Session Name</div>
                      <div className="col max-colagenda">Session Date</div>
                      <div className="col">Created At</div>
                      <div className="col text-end">Action</div>
                    </div>

                    {filteredSessions.length > 0 ? (
                      filteredSessions.map((session) => (
                        <div className="row py-2 ft-table text-start" key={session.id}>
                          <div className="col ">{session.session_name}</div>
                          <div className="col max-colagenda">
                            {session.session_date
                              ? new Date(session.session_date).toLocaleDateString()
                              : "N/A"}
                          </div>
                          <div className="col">
                            {session.created_at
                              ? new Date(session.created_at).toLocaleString()
                              : "N/A"}
                          </div>
                          <div className="col text-end">
                            <span className="nav_iconAdd text-center w-50 px-3">
                            <Link
                              className="text-white"
                              onClick={() => handleEdit(session.id)} // Call handleEdit
                            >
                              <FontAwesomeIcon
                                icon={faEdit}
                                className="text-white"
                              /> Add Agenda
                            </Link>
                            </span>

                            <span className="nav_iconedit text-center w-50 px-3">
                            <Link
                              className="text-white"
                              onClick={() => handleEdit(session.id)} // Call handleEdit
                            >
                              <FontAwesomeIcon
                                icon={faEdit}
                                className="text-white"
                              /> 
                            </Link>
                            </span>
                            

                            <span className="nav_icondelete text-center w-50 px-3">
                            <Link
                              className="text-white"
                              onClick={() => handleDelete(session.id)}
                            >
                              <FontAwesomeIcon icon={faTrashAlt} />
                            </Link>
                            </span>
                            
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="no-records">
                        <div className="icon">😞</div>
                        <div>No sessions found.</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SessionAgenda;
