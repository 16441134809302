import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import NavbarVisitor from "../Component/NavbarVisitor";
import BASE_URL from '../Component/Baseurl';

const SponserFormVisitor = () => {
    const { event_unique_id } = useParams(); // Extract event_unique_id from URL
    const [formData, setFormData] = useState(null);
    const [error, setError] = useState('');
    const [formFields, setFormFields] = useState({});
    const [formResponses, setFormResponses] = useState([]);

    useEffect(() => {
      // Function to fetch form data from the API using event_unique_id
      const fetchFormData = async () => {
        try {
          const response = await axios.get(`${BASE_URL}fetch_sponser_form/${event_unique_id}`);
          console.log('API hit successful', response.data); // Log API response

          if (response.data) {
            setFormData(response.data); // Set formData with the API response
            // Initialize formFields state
            const initialFields = response.data.fields.reduce((acc, field) => {
              acc[field.id] = field.field_type === 'checkbox' ? [] : '';
              return acc;
            }, {});
            setFormFields(initialFields);
          } else {
            setError('No form data found.');
            console.warn('No form data returned from API');
          }
        } catch (err) {
          setError('Error fetching form data.');
          console.error('Error during API call:', err);
        }
      };

      // Fetch data for the event_unique_id from URL
      fetchFormData();
    }, [event_unique_id]);

    const handleFieldChange = (e, fieldId) => {
      const { name, type, checked, value } = e.target;
      if (type === 'checkbox') {
        setFormFields(prev => ({
          ...prev,
          [fieldId]: checked
            ? [...prev[fieldId], value]
            : prev[fieldId].filter(v => v !== value)
        }));
      } else {
        setFormFields(prev => ({
          ...prev,
          [fieldId]: value
        }));
      }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
      
        try {
          const formFor = encodeURIComponent('Sponser Form'); // URL encode the form name
          const response = await axios.post(
            `${BASE_URL}submit_delegate_form/${event_unique_id}/${formFor}`, // Construct the URL
            formFields
          );
          console.log('Form submitted successfully', response.data);
          toast.success('Form submitted successfully!');
        } catch (err) {
          console.error('Error during form submission:', err);
          toast.error('Error submitting the form.');
        }
      };

    if (error) return <p className="text-danger">{error}</p>;
    if (!formData) return <p>Loading...</p>;

    return (
      <>
        <NavbarVisitor />

        <div className="container">
          <div className="p-0 position-relative">
            <div className="background-overlay"></div>
            <div className="d-flex justify-content-center align-items-center">
              <div className="col-md-12 d-flex align-items-center justify-content-center">
                <div className="container my-3">
                  <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-8 col-sm-12">
                      <div className="card shadow-lg rounded">
                        <div className="card-body p-3"> 
                          <div className="container">
                            <h2 className="text-center mb-4">Form Details</h2>
                            <form onSubmit={handleSubmit}>
                              <div className="card p-4">
                                <h3>{formData.form_name}</h3>
                                {formData.fields && formData.fields.length > 0 ? (
                                  <div>
                                    {formData.fields.map((field) => (
                                      <div key={field.id} className="mb-3 text-start">
                                        <label className="form-label">
                                          {field.field_name}
                                          {field.required && <span className="text-danger">*</span>}
                                        </label>
                                        {field.field_type === 'text' && 
                                          <input 
                                            type="text" 
                                            className="form-control" 
                                            value={formFields[field.id] || ''}
                                            onChange={(e) => handleFieldChange(e, field.id)}
                                          />
                                        }
                                        {field.field_type === 'textarea' && 
                                          <textarea 
                                            className="form-control" 
                                            rows="3"
                                            value={formFields[field.id] || ''}
                                            onChange={(e) => handleFieldChange(e, field.id)}
                                          />
                                        }
                                        {field.field_type === 'checkbox' && 
                                          field.options && field.options.length > 0 &&
                                          field.options.map((option, index) => (
                                            <div key={index} className="form-check">
                                              <input 
                                                className="form-check-input" 
                                                type="checkbox" 
                                                value={option}
                                                checked={formFields[field.id]?.includes(option) || false}
                                                onChange={(e) => handleFieldChange(e, field.id)}
                                                id={`checkbox-${field.id}-${index}`}
                                              />
                                              <label className="form-check-label" htmlFor={`checkbox-${field.id}-${index}`}>
                                                {option}
                                              </label>
                                            </div>
                                          ))
                                        }
                                        {field.field_type === 'radio' && 
                                          field.options && field.options.length > 0 &&
                                          field.options.map((option, index) => (
                                            <div key={index} className="form-check">
                                              <input 
                                                className="form-check-input" 
                                                type="radio" 
                                                name={field.id} 
                                                value={option}
                                                checked={formFields[field.id] === option}
                                                onChange={(e) => handleFieldChange(e, field.id)}
                                                id={`radio-${field.id}-${index}`}
                                              />
                                              <label className="form-check-label" htmlFor={`radio-${field.id}-${index}`}>
                                                {option}
                                              </label>
                                            </div>
                                          ))
                                        }
                                        {field.field_type === 'file' && 
                                          <input 
                                            type="file" 
                                            className="form-control"
                                            onChange={(e) => handleFieldChange(e, field.id)}
                                          />
                                        }
                                      </div>
                                    ))}
                                  </div>
                                ) : (
                                  <p>No fields available for this form.</p>
                                )}
                                <button type="submit" className="btn btn-primary">Submit</button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> 
            </div>
          </div>
        </div>

        <ToastContainer />
      </>
    );
};

export default SponserFormVisitor;