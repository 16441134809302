import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axiosInstance from '../../axiosInstance'; // Adjust the import based on your project structure
import { toast, ToastContainer } from 'react-toastify'; // Import react-toastify
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for react-toastify
import { useEvent } from '../../contexts/EventContext';
import OrgTopBar from '../OrgTopBar';
import Swal
 from 'sweetalert2';
const AddExhibitorsForm = ({ handleClose }) => {
  const { eventId } = useEvent(); // Get eventId from context
  const [formData, setFormData] = useState({
    name: '',
    designation: '',
    company: '',
    email: '',
    phone: '',
    boothNo: '',
    image: null
  });

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: name === 'image' ? files[0] : value
    }));
  };

  const [imagePreview, setImagePreview] = useState(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleButtonClick = () => {
    document.getElementById('imageUploadInput').click();
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Validate required fields
    const requiredFields = ['name', 'designation'];
    const missingFields = requiredFields.filter(field => !formData[field]);
  
    if (missingFields.length > 0) {
      missingFields.forEach(field => {
        Swal.fire({
          title: 'Error',
          text: `${capitalizeFirstLetter(field)} is required.`,
          icon: 'error',
          confirmButtonText: 'OK'
        });
      });
      return; // Stop form submission if there are missing fields
    }
  
    const data = new FormData();
    data.append('eventId', eventId); // Ensure eventId is appended
    data.append('name', formData.name);
    data.append('designation', formData.designation);
    data.append('company', formData.company);
    data.append('email', formData.email);
    data.append('phone', formData.phone);
    data.append('boothNo', formData.boothNo);
    if (formData.image) {
      data.append('image', formData.image);
    }
  
    try {
      await axiosInstance.post('/add_event_exhibitor', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
  
      // Show SweetAlert2 success message with confirmation button
      Swal.fire({
        title: 'Success!',
        text: 'Exhibitor added successfully!',
        icon: 'success',
        confirmButtonText: 'OK'
      }).then((result) => {
        if (result.isConfirmed) {
          // Reload the page after confirmation
          window.location.reload();
        }
      });
  
    } catch (error) {
      console.error('Error adding exhibitor:', error);
  
      // Show SweetAlert2 error message
      Swal.fire({
        title: 'Error',
        text: 'Error adding exhibitor. Please try again.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  };
  
  

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div className="container-dash p-0 position-relative">

<OrgTopBar/>
      <div className="background-overlay"></div>
      <div className="d-flex justify-content-center align-items-center">
        <div className="col-md-12 d-flex align-items-center justify-content-center">
          <div className="container my-3">
            <div className="row justify-content-center">

            


              <div className="col-lg-12 col-md-12 col-sm-12"> 

              <div class="headingcard align-content-center gradient-border py-1 mb-2">
                  <h1 class="text-start text-white px-3 titleh1">
                  Add Exhibitors
                  </h1>
                  <p class="text-start text-white px-3 pb-0 ">
                   Add Exhibitor Details. This will Reflect on event details page.
                  </p>
                </div>


                <div className="card shadow-lg rounded">
                  <div className="card-body p-3">
                    <form onSubmit={handleSubmit} className="text-start">
                      <div className="mb-3">
                        <div className="row">
                          <div className="col-md-3 text-end">
                            <label htmlFor="exhibitorName" className="form-label lable-cl">Name</label>
                          </div>
                          <div className="col-md-9">
                            <input
                              type="text"
                              id="exhibitorName"
                              name="name"
                              className="form-control"
                              placeholder="Enter Exhibitor's name"
                              value={formData.name}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mb-3">
                        <div className="row">
                          <div className="col-md-3 text-end">
                            <label htmlFor="exhibitorDesignation" className="form-label lable-cl">Designation</label>
                          </div>
                          <div className="col-md-9">
                            <input
                              type="text"
                              id="exhibitorDesignation"
                              name="designation"
                              className="form-control"
                              placeholder="Enter Designation"
                              value={formData.designation}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mb-3">
                        <div className="row">
                          <div className="col-md-3 text-end">
                            <label htmlFor="exhibitorCompany" className="form-label lable-cl">Company</label>
                          </div>
                          <div className="col-md-9">
                            <input
                              type="text"
                              id="exhibitorCompany"
                              name="company"
                              className="form-control"
                              placeholder="Enter Company name"
                              value={formData.company}
                              onChange={handleInputChange}
                               
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mb-3">
                        <div className="row">
                          <div className="col-md-3 text-end">
                            <label htmlFor="exhibitorEmail" className="form-label lable-cl">Email</label>
                          </div>
                          <div className="col-md-9">
                            <input
                              type="email"
                              id="exhibitorEmail"
                              name="email"
                              className="form-control"
                              placeholder="Enter Email"
                              value={formData.email}
                              onChange={handleInputChange}
                               
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mb-3">
                        <div className="row">
                          <div className="col-md-3 text-end">
                            <label htmlFor="exhibitorPhone" className="form-label lable-cl">Phone</label>
                          </div>
                          <div className="col-md-9">
                            <input
                              type="tel"
                              id="exhibitorPhone"
                              name="phone"
                              className="form-control"
                              placeholder="Enter Phone Number"
                              value={formData.phone}
                              onChange={handleInputChange}
                               
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mb-3">
                        <div className="row">
                          <div className="col-md-3 text-end">
                            <label htmlFor="exhibitorBoothNo" className="form-label lable-cl">Booth No</label>
                          </div>
                          <div className="col-md-9">
                            <input
                              type="text"
                              id="exhibitorBoothNo"
                              name="boothNo"
                              className="form-control"
                              placeholder="Enter Booth Number"
                              value={formData.boothNo}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mb-3">
                        <div className="row">
                          <div className="col-md-3 text-end">
                            <label className="form-label lable-cl">Image</label>
                          </div>
                          <div className="col-md-9">
                            <div className="image-upload-container">
                              <input
                                type="file"
                                id="imageUploadInput"
                                name="image"
                                style={{ display: 'none' }}
                                onChange={handleImageChange}
                              />
                              <button
                                type="button"
                                className="btn org-btn text-light w-75 bg-gradient-primary"
                                onClick={handleButtonClick}
                              >
                                Upload
                              </button>
                              {imagePreview && (
                                <img
                                  src={imagePreview}
                                  alt="Preview"
                                  className="img-thumbnail mt-2"
                                  style={{ maxWidth: '100%', maxHeight: '100%' }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="text-end">
                        <button type="submit" className="btn btnn org-btn text-light w-25">Submit</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer /> {/* Add ToastContainer to your component tree */}
    </div>
  );
};

export default AddExhibitorsForm;
