// src/Organiser/DashboardOrganiser.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Link, Navigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import FirstSidebarOrganiser from "../Component/FirstSidebarOrganiser";
import BASE_URL from "../Component/Baseurl";
import IMAGE_URL from "../Component/Baseurlimg";
import img1 from "../Component/Img/default.jpg";
import { Spinner } from "react-bootstrap";
import OrgTopBar from "../Component/OrgTopBar";

const DashboardOrganiser = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [events, setEvents] = useState([]); // State to hold events
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuthentication = async () => {
      const randomToken = localStorage.getItem("random_token");
      const organiserId = localStorage.getItem("organiser_id");

      console.log("random_token:", randomToken);
      console.log("organiser_id:", organiserId);

      if (randomToken && organiserId) {
        setIsAuthenticated(true);
        try {
          const response = await axios.post(`${BASE_URL}/events`, {
            organiserId,
          });
          console.log("Events:", response.data);
          setEvents(response.data);

          // Simulate a 1-second delay before setting loading to false
          setTimeout(() => {
            setLoading(false);
          }, 500);
        } catch (error) {
          console.error("Error fetching events:", error);
          if (error.response && error.response.status === 404) {
            toast.error("No records found");
          } else {
            toast.error("Failed to fetch events");
          }
          setLoading(false);
        }
      } else {
        setIsAuthenticated(false);
        setLoading(false);
      }
    };

    checkAuthentication();
  }, []);

  if (isAuthenticated === false) {
    return <Navigate to="/Login" />;
  }

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  return (
    <>
      <ToastContainer />
      <FirstSidebarOrganiser />

      <div className="container-dash p-0 position-relative">

<OrgTopBar/>

        <div className="background-overlay"></div>

        <div className="d-flex justify-content-center align-items-center">
          <div className="col-md-12 d-flex align-items-center justify-content-center">
            <div className="container my-3">
              <div className="row justify-content-center">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="card shadow-lg rounded">
                    <div className="card-body p-3">
                      {events.length > 0 ? (
                        <> 
                           
                          <div class="headingcard align-content-center gradient-border py-1 mb-2">
                            <h1 class="text-start text-white px-3 titleh1">Your Events</h1>
                            <p class="text-start text-white px-3 pb-0 ">Welcome to the Admin Panel..</p>
                          </div>


                          <div className="row">
                            {events.map((event) => (
                              <div
                                className="col-lg-4 col-md-6 mb-4"
                                key={event.id}
                              >
                                <div className="card ">
                                  <Link to={`/events/${event.event_unique_id}`}>
                                    <div className="card-body p-0">
                                      <div className="imghvr">
                                        <img
                                          src={
                                            event.event_image
                                              ? `${IMAGE_URL}${event.event_image}`
                                              : img1
                                          }
                                          className="img-fluid"
                                          alt={`Event ${event.event_name}`}
                                          loading="lazy" // Native lazy loading
                                          style={{
                                            width: "100%",
                                            height: "auto",
                                            objectFit: "cover",
                                            minHeight: "160px",
                                          }}
                                        />
                                        <div className="text-start text-start-b">
                                          <span className="badge bg-gradient-premium f-badge">
                                            {event.event_type}
                                          </span>
                                        </div>
                                      </div>

                                      <div className="text-start px-2 mt-0">
                                        <span className="badge bg-gradient-secondary f-badge ft-9">
                                          {new Date(
                                            event.event_start_date
                                          ).toLocaleDateString("en-GB", {
                                            day: "2-digit",
                                            month: "short",
                                            year: "numeric",
                                          })}
                                          &nbsp;-&nbsp;
                                          {new Date(
                                            event.event_end_date
                                          ).toLocaleDateString("en-GB", {
                                            day: "2-digit",
                                            month: "short",
                                            year: "numeric",
                                          })}
                                        </span>
                                        &nbsp;
                                        <span
                                          className={`badge f-badge ft-9 ${
                                            event.event_status === "draft"
                                              ? "bg-gradient-danger"
                                              : "bg-gradient-info"
                                          }`}
                                        >
                                          {event.event_status}
                                        </span>
                                      </div>

                                      <h5 className="card-title evnt-title my-2 px-2">
                                        {event.event_name}
                                      </h5>
                                      {/* <div className="text-start px-2 text-dark  ">{event.event_industry}</div> */}

                                      {/* <p className="card-text">Unique ID: {event.event_unique_id}</p> */}
                                    </div>
                                  </Link>
                                </div>
                              </div>
                            ))}
                          </div>
                        </>
                      ) : (
                        <h2 className="text-center mb-4">No records found</h2>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardOrganiser;
