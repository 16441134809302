// src/Component/NavbarVisitor.js
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../Component/Img/logo.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faBars, faPlus, faHome, faCalendar, faStar, faMapMarkerAlt, faUser, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

const NavbarVisitor = () => {
  const navigate = useNavigate();

  // State for storing visitor information
  const [visitorName, setVisitorName] = useState(localStorage.getItem('visitor_name')); // Retrieves the name
  const [visitorId, setVisitorId] = useState(localStorage.getItem('visitor_id'));  
  const [visitorEmail, setVisitorEmail] = useState(localStorage.getItem('visitor_email')); // Retrieves the email

  // State for off-canvas menu
  const [menuOpen, setMenuOpen] = useState(false);

  // Toggle off-canvas menu
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // Function to handle login and sign-in redirects
  const handleRedirect = (redirectUrl) => {
    const currentUrl = window.location.href;
    localStorage.setItem('redirectUrl', currentUrl);
    navigate(redirectUrl);
  };

  // Handle logout
  const handleLogout = () => {
    localStorage.removeItem('visitor_id');
    localStorage.removeItem('visitor_email');
    localStorage.removeItem('visitor_name');
    setVisitorId(null);
    setVisitorEmail(null);
    setVisitorName(null);
    navigate('/home');
  };

  // Log stored values on component load
  useEffect(() => {
    console.log("Visitor ID:", visitorId || "No visitor ID found");
    console.log("Visitor Name:", visitorName || "No visitor name found");
    console.log("Visitor Email:", visitorEmail || "No visitor email found");
  }, [visitorId, visitorName, visitorEmail]);

  return (
    <>
      {/* Section 1 - Desktop Navbar */}
      <section className="bg-light hide-on-mobile">
        <div className="container-fluid px-5">
          <div className="row py-1">
            <div className="col">
              <div className="text-start sptxt">Welcome to Evventoz</div>
            </div>
            <div className="col">
              <div className="text-end">
                <Link to="/Login" className="mx-2 text-dark sptxt">
                  <FontAwesomeIcon
                    icon={faPlus}
                    style={{ cursor: "pointer", color: "#000", fontSize: "18px" }}
                  /> Add Event
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-nav sticky-top">
          <div className="row py-2">
            <div className="col-2">
              <Link className="navbar-brand text-white" to="/">
                <img src={logo} alt="Logo" className="img-fluid logocls" />
              </Link>
            </div>
            <div className="col-3">
              <input type="text" className="searchbox" placeholder="Search By Event Name, Location .." />
            </div>
            <div className="col-7 text-end px-5">
              <div>
                <Link to="/" className="mx-2 text-dark sptxt">Home</Link>
                <Link to="/all-events" className="mx-2 text-dark sptxt">Events</Link>
                <Link to="/top-events" className="mx-2 text-dark sptxt">Top Shows</Link>
                <Link to="/venues" className="mx-2 text-dark sptxt">Venues</Link>

                {visitorName ? (
                  <div className="dropdown d-inline">
                    <span className="mx-2 text-dark sptxt dropdown-toggle curces" data-bs-toggle="dropdown">
                      {visitorName}
                    </span>
                    <ul className="dropdown-menu">
                      <li>
                        <span className="dropdown-item" style={{ cursor: "pointer" }}>
                          <FontAwesomeIcon icon={faSignOutAlt} style={{ fontSize: "18px" }} /> Profile
                        </span>
                      </li>
                      <li>
                        <span className="dropdown-item" onClick={handleLogout} style={{ cursor: "pointer" }}>
                          <FontAwesomeIcon icon={faSignOutAlt} style={{ fontSize: "18px" }} /> Logout
                        </span>
                      </li>
                    </ul>
                  </div>
                ) : (
                  <span className="mx-2 text-dark sptxt" onClick={() => handleRedirect('/visitor-login')} style={{ cursor: "pointer" }}>
                    <FontAwesomeIcon icon={faUser} style={{ fontSize: "18px" }} /> Login/Signin
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Section 2 - Mobile Navbar */}
      <section className="hide-on-desktop mobnav">
        <div className="row px-3">
          <div className="col">
            <Link className="navbar-brand text-white" to="/">
              <img src={logo} alt="Logo" className="img-fluid logocls" />
            </Link>
          </div>
          <div className="col text-end">
            <FontAwesomeIcon icon={faBars} style={{ fontSize: "18px", color: "#fff", cursor: "pointer" }} onClick={toggleMenu} />
          </div>
        </div>
      </section>
 
      {/* Off-Canvas Menu */}
      <div className={`offcanvas-menu ${menuOpen ? "open" : ""}`}>
        <ul>
          <li><Link to="/" onClick={toggleMenu}>
          <FontAwesomeIcon icon={faHome} style={{ fontSize: "13px",margin:"0px 10px 0px 0px", color: "#fff", cursor: "pointer" }} />
          Home</Link></li>
          <li><Link to="/all-events" onClick={toggleMenu}>
          <FontAwesomeIcon icon={faCalendar} style={{ fontSize: "13px",margin:"0px 10px 0px 0px", color: "#fff", cursor: "pointer" }} />
          Events</Link></li>
          <li><Link to="/top-events" onClick={toggleMenu}>
          <FontAwesomeIcon icon={faStar} style={{ fontSize: "13px",margin:"0px 10px 0px 0px", color: "#fff", cursor: "pointer" }} />
          Top Shows</Link></li>
          <li><Link to="/venues" onClick={toggleMenu}>
          <FontAwesomeIcon icon={faSignOutAlt} style={{ fontSize: "13px",margin:"0px 10px 0px 0px", color: "#fff", cursor: "pointer" }} />
          Venues</Link></li>
          {visitorName ? (
            <>
              <li><span onClick={toggleMenu}>
              <FontAwesomeIcon icon={faUser} style={{ fontSize: "13px",margin:"0px 10px 0px 0px", color: "#fff", cursor: "pointer" }} />
              Profile</span></li>
              <li><span onClick={handleLogout}>
              <FontAwesomeIcon icon={faSignOutAlt} style={{ fontSize: "13px",margin:"0px 10px 0px 0px", color: "#fff", cursor: "pointer" }} />
              Logout</span></li>
            </>
          ) : (
            <li><span onClick={() => handleRedirect('/visitor-login')}>Login/Signin</span></li>
          )}
        </ul>
      </div>

      {/* Overlay */}
      <div className={`overlay ${menuOpen ? "show" : ""}`} onClick={toggleMenu}></div>
    </>
  );
};

export default NavbarVisitor;
