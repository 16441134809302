import React, { useState } from "react";
import axiosInstance from "../../axiosInstance";
import { useEvent } from "../../contexts/EventContext"; // Import useEvent from EventContext
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";  
import "react-toastify/dist/ReactToastify.css";  
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons'; 


const ExibitorForm = () => {
  const { eventId } = useEvent(); // Destructure eventId from useEvent hook

  const [formName, setFormName] = useState("");
  const [formFor, setFormFor] = useState("Exhibitor Form");
  const [fields, setFields] = useState([]);
  const [fieldType, setFieldType] = useState("text");
  const [fieldName, setFieldName] = useState("");
  const [options, setOptions] = useState("");
  const [isRequired, setIsRequired] = useState(false);
  const [formError, setFormError] = useState("");
  const [file, setFile] = useState(null);

  const addField = () => {
    if (!fieldName) {
      setFormError("Field name is required.");
      return;
    }
    setFormError("");

    const newField = {
      id: Date.now(),
      name: fieldName,
      type: fieldType,
      options:
        fieldType === "radio" || fieldType === "checkbox"
          ? options.split(",")
          : [],
      required: isRequired,
    };
    setFields([...fields, newField]);
    setFieldName("");
    setOptions("");
    setIsRequired(false);
  };

  const removeField = (id) => {
    setFields(fields.filter((field) => field.id !== id));
  };

  const saveForm = async () => {
    if (!formName) {
      setFormError("Form name is required.");
      return;
    }
    if (!eventId) {
      setFormError("Event ID is required.");
      return;
    }
    if (fields.length === 0) {
      setFormError("Please add at least one field.");
      return;
    }
    if (
      file &&
      !["image/png", "image/jpeg", "application/pdf"].includes(file.type)
    ) {
      setFormError(
        "Invalid file type. Please upload PNG, JPG, JPEG, or PDF only."
      );
      return;
    }
    setFormError("");
  
    try {
      const formData = new FormData();
      formData.append("formName", formName);
      formData.append("formFor", formFor);
      formData.append("eventUniqueId", eventId);
      formData.append("fields", JSON.stringify(fields));
      if (file) formData.append("file", file);
  
      // Log the form data being sent
      console.log("Sending form data:", {
        formName,
        formFor,
        eventUniqueId: eventId,
        fields,
        file: file ? file.name : null,
      });
  
      const response = await axiosInstance.post("/create", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
  
      // Log the response from the server
      console.log("Response from server:", response.data);
  
      // Show success toast message
      toast.success("Form created successfully!");
  
      // Handle successful form save (e.g., show a success message, redirect, etc.)
    } catch (error) {
      console.error("Error saving form:", error);
      setFormError("Error saving form. Please try again.");
    }
  };
  

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (
      selectedFile &&
      ["image/png", "image/jpeg", "application/pdf"].includes(selectedFile.type)
    ) {
      setFile(selectedFile);
      setFormError("");
    } else {
      setFile(null);
      setFormError(
        "Invalid file type. Please upload PNG, JPG, JPEG, or PDF only."
      );
    }
  };

  return (
    <div className="container-dash p-0 position-relative">
      <div className="background-overlay"></div>
      <div className="d-flex justify-content-center align-items-center">
        <div className="col-md-12 d-flex align-items-center justify-content-center">
          <div className="container my-3">
            <div className="row justify-content-center">
              <div className="col-md-12">
                <h1 className="mb-0 h1-cl text-start">
                  Create a Custom Exhibitor Form
                </h1>
              </div>

              <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
                <div className="card shadow-lg rounded">
                  <div className="card-body p-3">
                    {/* <h2 className="text-center mb-4"></h2> */}

                    <div className="row">
                      <div className="col-md-6 card mt-3">
                        <div className="mb-3 text-start">
                          <label htmlFor="formName" className="mb-2">
                            Form Name<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="formName"
                            value={formName}
                            onChange={(e) => setFormName(e.target.value)}
                            placeholder="Enter form name"
                          />
                        </div>

                        <div className="mb-3 text-start">
                          <label htmlFor="formFor" className="mb-2">
                            Form For<span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-control inputgroup2"
                            id="formFor"
                            value={formFor}
                            onChange={(e) => setFormFor(e.target.value)}
                          >
                            <option value="Exhibitor Form">Exhibitor Form</option>
                          </select>
                        </div>

                        <div className="mb-3 text-start">
                          <label htmlFor="fieldType" className="mb-2">
                            Field Type
                          </label>
                          <select
                            className="form-control inputgroup2"
                            id="fieldType"
                            value={fieldType}
                            onChange={(e) => setFieldType(e.target.value)}
                          >
                            <option value="text">Text</option>
                            <option value="textarea">Textarea</option>
                            <option value="checkbox">Checkbox</option>
                            <option value="radio">Radio</option>
                            <option value="file">File Upload</option>
                          </select>
                        </div>

                        <div className="mb-3 text-start">
                          <label htmlFor="fieldName" className="mb-2">
                            Field Name<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="fieldName"
                            value={fieldName}
                            onChange={(e) => setFieldName(e.target.value)}
                            placeholder="Enter field name"
                          />
                        </div>

                        {(fieldType === "radio" ||
                          fieldType === "checkbox") && (
                          <div className="mb-3 text-start">
                            <label htmlFor="options" className="mb-2">
                              Options (comma separated)
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="options"
                              value={options}
                              onChange={(e) => setOptions(e.target.value)}
                              placeholder="Option1,Option2,Option3"
                            />
                          </div>
                        )}

                        {fieldType === "file" && (
                          <div className="mb-3 text-start">
                            <label htmlFor="fileUpload" className="mb-2">
                              Upload File (PNG, JPG, JPEG, PDF)
                            </label>
                            <input
                              type="file"
                              className="form-control"
                              id="fileUpload"
                              onChange={handleFileChange}
                            />
                          </div>
                        )}

                        <div className="form-check text-start mb-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="isRequired"
                            checked={isRequired}
                            onChange={(e) => setIsRequired(e.target.checked)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="isRequired"
                          >
                            Required Field
                          </label>
                        </div>

                        {formError && (
                          <p className="text-danger">{formError}</p>
                        )}

                        <button
                          className="btn btn-primary mb-3"
                          onClick={addField}
                        >
                          Add Field
                        </button>
                        <button
                          className="btn btn-success mb-3 ms-2"
                          onClick={saveForm}
                        >
                          Save Form
                        </button>
                      </div>
                      <div className="col-md-6">
                        <h3>Form Preview:</h3>
                        <div className="card p-3">
                          <p className="text-dark-orange text-center"><strong>{formName}</strong></p>
                          {fields.map((field) => (
                            <div key={field.id} className="mb-2 text-start">
                              <span className="text-dark">{field.name}</span>
                              {field.type === "text" && (
                                <input
                                  type="text"
                                  className="form-control mt-1"
                                />
                              )}
                              {field.type === "textarea" && (
                                <textarea
                                  className="form-control mt-1"
                                  rows="3"
                                ></textarea>
                              )}
                              {field.type === "checkbox" &&
                                field.options.map((option, index) => (
                                  <div key={index} className="form-check">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                    />
                                    <label className="form-check-label">
                                      {option}
                                    </label>
                                  </div>
                                ))}
                              {field.type === "radio" &&
                                field.options.map((option, index) => (
                                  <div key={index} className="form-check">
                                    <input
                                      type="radio"
                                      className="form-check-input"
                                      name={field.name}
                                    />
                                    <label className="form-check-label">
                                      {option}
                                    </label>
                                  </div>
                                ))}
                              {field.type === "file" && (
                                <input type="file" className="form-control" />
                              )}
                              <div className="text-end">
                              <button
                                className="  btn-danger mt-2"
                                onClick={() => removeField(field.id)}
                              >
                                <FontAwesomeIcon icon={faTrashCan}  />
                              </button>
                              </div>
                              
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer /> {/* Add ToastContainer here */}
    </div>
  );
};

export default ExibitorForm;
