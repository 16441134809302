import React, { useEffect, useState } from "react";
import axiosInstance from "../axiosInstance";
import profile from "../Component/Img/profile.jpg";
import { Link, useParams, useNavigate } from "react-router-dom";

const OrgTopBar = () => {
  const [accountPlan, setAccountPlan] = useState(null);
  const organiserId = localStorage.getItem("organiser_id");

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (organiserId) {
          const response = await axiosInstance.get(`organiser/${organiserId}`);
          console.log("API response:", response.data);
          setAccountPlan(response.data.account_plan); // Store the account plan in state
        } else {
          console.log("No organiser ID found in local storage.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [organiserId]);

  const renderUpgradeButton = () => {
    switch (accountPlan) {
      case "Basic":
        return <span className="upgradebtn bg-gradient-secondary">Upgrade To Gold</span>;
      case "Gold":
        return <span className="upgradebtn bg-gradient-info">Upgrade To Advance</span>;
      case "Advance":
        return <span className="upgradebtn bg-gradient-premium">Upgrade To Premium</span>;
      case "Premium":
        return <span className="upgradebtn bg-gradient-primary">Premium</span>;
      default:
        return null;
    }
  };

  return (
    <div className="container pt-2">
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="row bdrds1 align-items-center py-2 sticky-top px-3">
              <div className="col text-start">
                <Link to="/package">
                {renderUpgradeButton()}
                </Link>
              
              </div>
              <div className="col text-end">
                <div>
                  
                  <span>
                    <img
                      src={profile}
                      className="img-fluid rounded-circle"
                      alt="Profile"
                      style={{
                        width: "40px",
                        height: "40px",
                        cursor: "pointer",
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Additional content can be added here if needed */}
    </div>
  );
};

export default OrgTopBar;
