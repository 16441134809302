import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css"; // Import the calendar styles
import BASE_URL from "../../Component/Baseurl";
import FirstSidebarOrganiser from "../FirstSidebarOrganiser";

const localizer = momentLocalizer(moment); // Set up the localizer

const EventCalendar = () => {
  const [events, setEvents] = useState([]); // State to hold events

  useEffect(() => {
    document.title = "Event Calendar"; // Set page title
    fetchEvents(); // Fetch events on component mount
  }, []);

  // Function to fetch events from the API
  const fetchEvents = async () => {
    try {
      const organiserId = localStorage.getItem("organiser_id"); // Get organiserId from localStorage
      const response = await fetch(`${BASE_URL}/events`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ organiserId }),
      });
      
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      // Map the response data to fit the calendar's expected structure
      const formattedEvents = data.map(event => ({
        id: event.id,
        title: event.event_name,
        start: new Date(event.event_start_date), // Assuming event_start_date is in a format compatible with Date constructor
        end: new Date(event.event_end_date), // Assuming event_end_date exists
      }));

      setEvents(formattedEvents); // Update state with fetched events
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  return (
    <div className="container-dash p-0 position-relative">

<FirstSidebarOrganiser/>

      <div className="background-overlay"></div>
      <div className="d-flex justify-content-center align-items-center">
        <div className="col-md-12 d-flex align-items-center justify-content-center">
          <div className="container my-3">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card shadow-lg rounded">
                  <div className="card-body p-3">
                    <div className="container">
                      <h2 className="text-center mb-4">Calendar</h2>
                      <Calendar
                        localizer={localizer}
                        events={events} // Use the fetched events
                        startAccessor="start"
                        endAccessor="end"
                        style={{ height: 500, margin: "50px" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCalendar;
