// src/Organiser/Package.js
import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { Link, Navigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import FirstSidebarOrganiser from "../Component/FirstSidebarOrganiser";
import OrgTopBar from "../Component/OrgTopBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import BASE_URL from "../Component/Baseurl"; // Make sure this path is correct

const Package = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [packages, setPackages] = useState([]);
  const [currency, setCurrency] = useState("INR"); // Default currency
  const [billingCycle, setBillingCycle] = useState("monthly"); // Default billing cycle

  useEffect(() => {
    const checkAuthentication = () => {
      const randomToken = localStorage.getItem("random_token");
      const organiserId = localStorage.getItem("organiser_id");

      if (randomToken && organiserId) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    };

    checkAuthentication();
  }, []);

  useEffect(() => {
    // Fetch package details from API
    const fetchPackageDetails = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/fetch-package-details`);
        setPackages(response.data.data);
      } catch (error) {
        console.error("Error fetching package details:", error);
      }
    };

    fetchPackageDetails();
  }, []);

  if (isAuthenticated === false) {
    return <Navigate to="/Login" />;
  }

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
  };

  const handleBillingCycleChange = (event) => {
    setBillingCycle(event.target.value);
  };

  // Define the gradient classes based on index
  const gradientClasses = [
    "bg-gradient-black",
    "bg-gradient-info",
    "bg-gradient-premium",
    "bg-gradient-primary",
    "bg-gradient-black",
  ];

  // Function to encrypt data (placeholder function)
  const encryptData = (data) => {
    // Implement your encryption logic here
    return btoa(JSON.stringify(data)); // Example: simple base64 encoding
  };

  return (
    <>
      <ToastContainer />
      <FirstSidebarOrganiser />

      <div className="container-dash p-0 position-relative">
        <OrgTopBar />

        <div className="background-overlay"></div>

        <div className="d-flex justify-content-center align-items-center">
          <div className="col-md-12 d-flex align-items-center justify-content-center">
            <div className="container my-3">
              <div className="row justify-content-center">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="card shadow-lg rounded nwbg-card">
                    <h2 className="text-center mb-3 heading-h2 mt-2">
                      Pricing Table
                    </h2>

                    {/* Currency Switcher */}
                    <div className="px-4">
                      <div className="row bg-gradient-black p-2 justify-content-end rounded">
                        <div className="col-md-3">
                          <span>Select Currency</span>
                          <span>
                            <select
                              value={currency}
                              className="slctcs"
                              onChange={handleCurrencyChange}
                            >
                              <option value="INR">INR</option>
                              <option value="USD">USD</option>
                            </select>
                          </span>
                        </div>
                        <div className="col-md-4">
                          <span>Select Billing Cycle</span>
                          <span>
                            <select
                              value={billingCycle}
                              className="slctcs"
                              onChange={handleBillingCycleChange}
                            >
                              <option value="monthly">Monthly</option>
                              <option value="quarterly">Quarterly</option>
                              <option value="half-yearly">Half-Yearly</option>
                              <option value="yearly">Yearly</option>
                            </select>
                          </span>
                        </div>
                      </div>
                    </div>

                    {/* Define Packages */}
                    <div className="row p-2">
                      {packages.map((pkg, index) => {
                        // Calculate the amount based on selected currency and billing cycle
                        const amount =
                          currency === "INR"
                            ? billingCycle === "monthly"
                              ? pkg.packagePriceInr
                              : billingCycle === "quarterly"
                              ? pkg.packageQuarterlyInr
                              : billingCycle === "half-yearly"
                              ? pkg.packageHalfyearInr
                              : pkg.packageYearInr
                            : billingCycle === "monthly"
                            ? pkg.packagePriceUsd
                            : billingCycle === "quarterly"
                            ? pkg.packageQuarterlyUsd
                            : billingCycle === "half-yearly"
                            ? pkg.packageHalfyearUsd
                            : pkg.packageYearUsd;

                        return (
                          <div key={pkg.packageId} className="col-md-3">
                            <div
                              className={`${
                                gradientClasses[index % gradientClasses.length]
                              } packagedesign1 p-2`}
                            >
                              <div className="text-center price1">
                                {pkg.packageName} <br />
                                {currency === "INR" ? (
                                  <>₹{amount}</>
                                ) : (
                                  <>${amount}</>
                                )}
                              </div>

                              <div className="text-start">
                                {pkg.packageItems.map((item) => (
                                  <div key={item.id}>
                                    <FontAwesomeIcon
                                      icon={faCheckCircle}
                                      className="nav_logo-icon"
                                    />{" "}
                                    {item.package_list_item}
                                  </div>
                                ))}
                              </div>

                              <div className="text-center price1 mt-2">
                                <Link
                                  to={{
                                    pathname: "/package-detail",
                                    search: `?currency=${encodeURIComponent(
                                      encryptData(currency)
                                    )}&billingCycle=${encodeURIComponent(
                                      encryptData(billingCycle)
                                    )}&packageId=${encryptData(
                                      pkg.packageId
                                    )}&amount=${encodeURIComponent(
                                      encryptData(amount)
                                    )}&packageName=${encodeURIComponent(
                                      encryptData(pkg.packageName)
                                    )}`,
                                  }}
                                  className="text-white"
                                >
                                  Buy Now
                                </Link>
                                Link
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Package;
