import React from "react";
import axios from "axios";
import BASE_URL from "../Component/Baseurl";

const PayButton = ({ packageId, currency, amount, packageName, billingCycle }) => {
    const handleCheckout = () => {
        const organiserId = localStorage.getItem("organiser_id");
        console.log("Organiser ID:", organiserId);
      
        console.log("Package ID:", packageId);
        console.log("Currency:", currency);
        console.log("Amount:", amount);  // This should be "400" (string)
        console.log("Package Name:", packageName);
        console.log("Billing Cycle:", billingCycle);
      
        // Remove leading zeros and convert to integer
        const numericAmount = parseInt(amount.replace(/^0+/, ''), 10);
      
        // Check if `numericAmount` is a valid number
        if (isNaN(numericAmount) || numericAmount <= 0) {
          console.error("Invalid amount provided. Please enter a positive integer.");
          return;
        }
      
        // Prepare the request data
        const requestData = {
          packageId,
          currency: currency.toLowerCase(),   
          amount: numericAmount,
          organiserId,
          packageName,
          billingCycle,
        };
      
        console.log("Request Data:", requestData);
      
        // Send the request to the backend
        axios.post(`${BASE_URL}stripe/create-checkout-session`, requestData)
          .then(response => {
            if (response.data.url) {
              window.location.href = response.data.url;  // Redirect to Stripe checkout
            }
          })
          .catch(error => console.error("Error during checkout:", error));
      };
      

  return (
    <button onClick={handleCheckout}>CheckOut</button>
  );
};

export default PayButton;
