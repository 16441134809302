import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BASE_URL from '../Component/Baseurl';
import { Link, Navigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const Registration = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [ipAddress, setIpAddress] = useState('');
    const [deviceOS, setDeviceOS] = useState('');
    const [error, setError] = useState('');
    const [redirectToVerifyEmail, setRedirectToVerifyEmail] = useState(false);
    const [agreePolicy, setAgreePolicy] = useState(false);
    const [passwordValidations, setPasswordValidations] = useState({
        hasCapital: false,
        hasLowercase: false,
        hasNumber: false,
        hasSpecialChar: false,
        isMinLength: false
    });
    const [passwordRequirementsVisible, setPasswordRequirementsVisible] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        const fetchIpAddress = async () => {
            try {
                const response = await axios.get('https://api.ipify.org?format=json');
                setIpAddress(response.data.ip);
            } catch (error) {
                console.error('Error fetching IP address:', error);
            }
        };

        const getDeviceOS = () => {
            const userAgent = window.navigator.userAgent;
            let os = 'Unknown OS';

            if (userAgent.indexOf('Win') !== -1) os = 'Windows';
            if (userAgent.indexOf('Mac') !== -1) os = 'MacOS';
            if (userAgent.indexOf('X11') !== -1) os = 'UNIX';
            if (userAgent.indexOf('Linux') !== -1) os = 'Linux';

            console.log('Detected Device OS:', os); // Log device OS information
            return os;
        };

        fetchIpAddress();
        const detectedOS = getDeviceOS();
        setDeviceOS(detectedOS);
    }, []);

    useEffect(() => {
        const validatePassword = () => {
            const hasCapital = /[A-Z]/.test(password);
            const hasLowercase = /[a-z]/.test(password);
            const hasNumber = /[0-9]/.test(password);
            const hasSpecialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password);
            const isMinLength = password.length >= 10;

            setPasswordValidations({
                hasCapital,
                hasLowercase,
                hasNumber,
                hasSpecialChar,
                isMinLength
            });
        };

        validatePassword();
    }, [password]);

    const handleRegister = async (e) => {
        e.preventDefault();

        // Validate form inputs
        if (!/^[a-zA-Z\s]{3,}$/.test(name)) {
            toast.error('Name must contain at least 3 letters and spaces only.');
            return;
        }

        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            toast.error('Invalid email format.');
            return;
        }

        if (!/^\d{10}$/.test(phone)) {
            toast.error('Phone number must be 10 digits long.');
            return;
        }

        if (!(passwordValidations.hasCapital && passwordValidations.hasLowercase && passwordValidations.hasNumber && passwordValidations.hasSpecialChar && passwordValidations.isMinLength)) {
            toast.error('Password must contain at least 1 uppercase, 1 lowercase, 1 special character, 1 number, and be at least 10 characters long.');
            return;
        }

        if (password !== confirmPassword) {
            toast.error('Passwords do not match.');
            return;
        }

        if (!agreePolicy) {
            toast.error('Please agree to the Privacy Policy and acknowledge to receive communication.');
            return;
        }

        try {
            // Register the user
            const registrationResponse = await axios.post(`${BASE_URL}organiser_registration_master`, {
                name,
                email,
                phone,
                password,
                ip_address: ipAddress,
                device_os: deviceOS
            });
    
            console.log('Registration Response:', registrationResponse.data);
    
            if (registrationResponse.data.success) {
                toast.success('Registration successful!', { autoClose: 2000 });
    
                // Store email in local storage
                localStorage.setItem('userEmail', email);
    
                // Redirect to dashboard (assuming the dashboard path is "/dashboard")
                setTimeout(() => {
                    window.location.href = "/Dashboard_Organiser";
                }, 2000);
            } else {
                setError(registrationResponse.data.message || 'Registration failed');
                toast.error(registrationResponse.data.message || 'Registration failed');
            }
        } catch (err) {
            console.error('Error registering:', err);
            setError('Registration failed');
            toast.error(err.response?.data?.message || 'Registration failed');
        }
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    const handlePasswordBlur = () => {
        setPasswordRequirementsVisible(false);
    };

    const handleCheckboxChange = (e) => {
        setAgreePolicy(e.target.checked);
    };

    if (redirectToVerifyEmail) {
        return <Navigate to="/VerifyEmailOrganiser" />;
    }
    return (
        <div className="container-fluid p-0 position-relative">
            <div className="background-overlay"></div>
            <div className="background-image"></div>
            <div className="d-flex justify-content-center align-items-center ">
                <div className="col-md-12 d-flex align-items-center justify-content-center">
                    <div className="container my-3">
                        <div className="row justify-content-center">
                            <div className="col-lg-8 col-md-10 col-sm-12">
                                <div className="card shadow-lg rounded">
                                    <div className="card-body px-5 py-3 pb-5">
                                        <h2 className="text-center mb-4">Organiser Register</h2>
                                        <form onSubmit={handleRegister}>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="mb-3 text-start">
                                                        <label htmlFor="name">Name</label>
                                                        <input
                                                            type="text"
                                                            className="inputgroup w-100"
                                                            id="name"
                                                            placeholder="Enter Name"
                                                            value={name}
                                                            onChange={(e) => setName(e.target.value)}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="mb-3 text-start">
                                                        <label htmlFor="email">Email</label>
                                                        <input
                                                            type="email"
                                                            className="inputgroup w-100"
                                                            id="email"
                                                            placeholder="Enter Email"
                                                            value={email}
                                                            onChange={(e) => setEmail(e.target.value)}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="mb-3 text-start">
                                                        <label htmlFor="phone">Mobile Number</label>
                                                        <input
                                                            type="text"
                                                            className="inputgroup w-100"
                                                            id="phone"
                                                            placeholder="Enter Phone"
                                                            value={phone}
                                                            onChange={(e) => setPhone(e.target.value)}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="mb-3 text-start position-relative">
                                                        <label htmlFor="mypassword">Password</label>
                                                        <input
                                                            type={showPassword ? 'text' : 'password'}
                                                            className="inputgroup w-100"
                                                            id="mypassword"
                                                            placeholder="Enter Password"
                                                            value={password}
                                                            onChange={handlePasswordChange}
                                                            onFocus={() => setPasswordRequirementsVisible(true)}
                                                            onBlur={handlePasswordBlur}
                                                            required
                                                        />
                                                        <div className="form-check mt-2">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="showPassword"
                                                                checked={showPassword}
                                                                onChange={toggleShowPassword}
                                                            />
                                                            <label className="form-check-label std-pt-size" htmlFor="showPassword">
                                                                Show Password
                                                            </label>
                                                        </div>
                                                        <div className={`password-requirements mt-2 ${passwordRequirementsVisible ? 'visible' : 'hidden'}`}>
                                                            <div className="row">
                                                                <div className="col-md-6 col-lg-6">
                                                                    <ul className="std-pt-size">
                                                                        <li style={{ color: passwordValidations.hasCapital ? 'green' : '#828282' }}>At least 1 Capital letter</li>
                                                                        <li style={{ color: passwordValidations.hasLowercase ? 'green' : '#828282' }}>At least 1 Small letter</li>
                                                                        <li style={{ color: passwordValidations.hasSpecialChar ? 'green' : '#828282' }}>At least 1 Special character</li>
                                                                    </ul>
                                                                </div>
                                                                <div className="col-md-6 col-lg-6">
                                                                    <ul className="std-pt-size">
                                                                        <li style={{ color: passwordValidations.hasNumber ? 'green' : '#828282' }}>At least 1 Number</li>
                                                                        <li style={{ color: passwordValidations.isMinLength ? 'green' : '#828282' }}>Minimum 10 characters</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="mb-3 text-start position-relative">
                                                        <label htmlFor="confirmPassword">Confirm Password</label>
                                                        <input
                                                            type={showPassword ? 'text' : 'password'}
                                                            className="inputgroup w-100"
                                                            id="confirmPassword"
                                                            placeholder="Confirm Password"
                                                            value={confirmPassword}
                                                            onChange={handleConfirmPasswordChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 text-start">
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="agreePolicy"
                                                            checked={agreePolicy}
                                                            onChange={handleCheckboxChange}
                                                            required
                                                        />
                                                        <label className="form-check-label" htmlFor="agreePolicy">
                                                            I agree to the <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> and acknowledge that I am open to receiving communication from the company.
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <button type="submit" className="btnnew w-100 mt-3 ">Register</button>
                                                </div>

                                                <div className="text-center mt-3">Already Have An Account&nbsp;
                      <Link to="/Login" className="">Login</Link>
                    </div>
 
                                            </div>
                                        </form>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default Registration;
