import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify'; // Import react-toastify
import 'react-toastify/dist/ReactToastify.css'; // Import CSS for react-toastify
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import axiosInstance from "../../axiosInstance"; // Import the Axios instance
import { useEvent } from "../../contexts/EventContext"; // Import the EventContext
import Swal from 'sweetalert2'; // Import SweetAlert
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const AllFaqList = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [faqs, setFaqs] = useState([]); // State to hold the FAQs

  const { eventId } = useEvent(); // Get the eventId from the EventContext

  // Fetch FAQs for the event when component is mounted
  useEffect(() => {
    const fetchFaqs = async () => {
      setLoading(true);
      setError(null);
      
      try {
        const response = await axiosInstance.get(`/faqs/${eventId}`);
        setFaqs(response.data.data); // Set the fetched FAQs to state
      } catch (error) {
        console.error("Error fetching FAQs:", error.response ? error.response.data : error.message);
        setError("An error occurred while fetching FAQs.");
      } finally {
        setLoading(false);
      }
    };

    if (eventId) {
      fetchFaqs();
    }
  }, [eventId]);

  // Function to handle deleting an FAQ
  const handleDeleteFaq = async (id) => {
    // Use SweetAlert for confirmation
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!'
    });

    if (result.isConfirmed) {
      setLoading(true);
      setError(null);
  
      try {
        await axiosInstance.delete(`/faq/${id}`); // Call the delete API
        toast.success("FAQ deleted successfully!");
        
        // Remove the deleted FAQ from state
        setFaqs((prevFaqs) => prevFaqs.filter((faq) => faq.id !== id));
      } catch (error) {
        console.error("Error deleting FAQ:", error.response ? error.response.data : error.message);
        setError("An error occurred while deleting the FAQ.");
        toast.error("Error deleting FAQ.");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
    <div className="px-3">

   
 
      <div className="tab-content" id="myTabContent">
        <div className="tab-pane fade show active w-100 text-start" id="home" role="tabpanel">
          {loading && <p>Loading FAQs...</p>}
          {error && <p className="text-danger">{error}</p>}
          
          <div className="row text-start ft-head-table py-2 mt-2 mb-2">
            <div className="col">FAQ Question</div>
            <div className="col">FAQ Answer</div>
            <div className="col text-end">Action</div>
          </div>

          {faqs.length > 0 ? (
            faqs.map((faq) => (
              <div className="row py-2 ft-table" key={faq.id}>
                <div className="col">{faq.faq_question}</div>
                <div className="col">{faq.faq_answer}</div>
                <div className="col text-end">
                  <button 
                    className="btn btn-danger btn-sm" 
                    onClick={() => handleDeleteFaq(faq.id)}
                  >
                   <FontAwesomeIcon icon={faTrash} className='nav_iconwht' />
                  </button>
                </div>
              </div>
            ))
          ) : (
            <div className="row text-start">
              <div className="col">No FAQs available.</div>
            </div>
          )}
        </div>
      </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default AllFaqList;
