import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axiosInstance from '../../axiosInstance'; // Adjust the import based on your project structure
import { toast, ToastContainer } from 'react-toastify'; // Import react-toastify
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for react-toastify
import { useEvent } from '../../contexts/EventContext';
import { useParams } from 'react-router-dom'; // Import useParams
import OrgTopBar from "../OrgTopBar";
import Swal from 'sweetalert2';

const UpdateAgenda = ({ handleClose }) => {
  const { eventId } = useEvent(); // Get eventId from context
  const { session_id } = useParams(); // Get session_id from URL
  const [formRows, setFormRows] = useState([
    {
      startTime: '',
      endTime: '',
      topic: '',
      speaker: ''
    }
  ]);

  useEffect(() => {
    console.log('Session ID from URL:', session_id);
    console.log('Event ID from context:', eventId); // Log eventId from context
    fetchAgenda(); // Fetch agenda data on mount
  }, [session_id, eventId]); 

  const fetchAgenda = async () => {
    try {
      const response = await axiosInstance.get(`/fetch_agenda_by_session_id/${eventId}/${session_id}`);
      if (response.data.length > 0) {
        setFormRows(response.data.map(item => ({
          startTime: item.start_time,
          endTime: item.end_time,
          topic: item.agenda_topic,
          speaker: item.agenda_speaker
        })));
      } else {
        toast.info('No existing agenda found for this session.');
      }
    } catch (error) {
      console.error('Error fetching agenda:', error);
      // toast.error('Failed to fetch agenda. Please try again.');
    }
  };

  const handleInputChange = (index, e) => {
    const { name, value } = e.target;
    const newFormRows = [...formRows];
    newFormRows[index][name] = value;
    setFormRows(newFormRows);
  };

  const handleAddRow = () => {
    setFormRows([
      ...formRows,
      {
        startTime: '',
        endTime: '',
        topic: '',
        speaker: ''
      }
    ]);
  };

  const handleRemoveRow = (index) => {
    if (formRows.length > 1) {
      const newFormRows = formRows.filter((_, i) => i !== index);
      setFormRows(newFormRows);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post('/update-agenda', {
        eventId,
        sessionId: session_id, // Send the session_id along with the agenda
        agenda: formRows // Send the list of agenda items
      });
  
      // Show SweetAlert2 success message with confirmation button
      Swal.fire({
        title: 'Success!',
        text: 'Agenda updated successfully!',
        icon: 'success',
        confirmButtonText: 'OK'
      }).then((result) => {
        if (result.isConfirmed) {
          // Redirect after confirmation
          window.location.href = `/events/${eventId}/session-and-agenda`;
        }
      });
  
    } catch (error) {
      console.error('Error updating agenda:', error);
  
      // Show SweetAlert2 error message
      Swal.fire({
        title: 'Error',
        text: 'Failed to update agenda. Please try again.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  };
  

  return (
    <div className="container-dash p-0 position-relative">
      <OrgTopBar/>
      <div className="background-overlay"></div>
      <div className="d-flex justify-content-center align-items-center">
        <div className="col-md-12 d-flex align-items-center justify-content-center">
          <div className="container my-3">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12 col-sm-12">
               

                <div class="headingcard align-content-center gradient-border py-1 mb-2">
                  <h1 class="text-start text-white px-3 titleh1">
                  Update Agenda
                  </h1>
                  {/* <p class="text-start text-white px-3 pb-0 ">
                    Below Is the list of created Session List.
                  </p> */}
                </div>


                <div className="card shadow-lg rounded">
                  <div className="card-body p-3">
                    <form onSubmit={handleSubmit} className="text-start">
                      {formRows.map((row, index) => (
                        <div className="mb-3" key={index}>
                          <div className="row rowclbd">

                            <div className="row mb-2 pt-2">
                            <div className="col-3 text-start  ">
                              <label htmlFor={`startTime-${index}`} className="form-label lable-cl">Start Time</label>
                            </div>
                            <div className="col-4 text-start ">
                              <input
                                type="time"
                                id={`startTime-${index}`}
                                name="startTime"
                                className="form-control"
                                value={row.startTime}
                                onChange={(e) => handleInputChange(index, e)}
                                required
                              />
                            </div>
                            </div>
                            


                            <div className="row mb-2">
                            <div className="col-3 text-start  ">
                              <label htmlFor={`endTime-${index}`} className="form-label lable-cl">End Time</label>
                            </div>
                            <div className="col-4 text-start ">
                               
                              <input
                                type="time"
                                id={`endTime-${index}`}
                                name="endTime"
                                className="form-control"
                                value={row.endTime}
                                onChange={(e) => handleInputChange(index, e)}
                                required
                              />
                            </div>
                            </div>
                            

                            <div className="row mb-2">
                            <div className="col-3 text-start">
                              <label htmlFor={`topic-${index}`} className="form-label lable-cl">Enter Topic</label>
                               
                            </div>

                            <div className="col-8 text-start">
                               
                              <textarea
                                id={`topic-${index}`}
                                name="topic"
                                className="form-control"
                                placeholder="Enter topic"
                                value={row.topic}
                                onChange={(e) => handleInputChange(index, e)}
                                required
                              />
                            </div>
                            </div>
                            

                            <div className="row mb-2">
                            <div className="col-3 text-start">
                              <label htmlFor={`speaker-${index}`} className="form-label lable-cl">Enter Speaker</label>
                               
                            </div>
                            <div className="col-8 text-start">
                              
                              <textarea
                                id={`speaker-${index}`}
                                name="speaker"
                                className="form-control"
                                placeholder="Enter speaker"
                                value={row.speaker}
                                onChange={(e) => handleInputChange(index, e)}
                                required
                              />
                            </div>
                            </div>
                            

                            {/* Add Remove Button for rows except the first one */}
                            {formRows.length > 1 && (
                              <div className="text-end mt-2">
                                <button
                                  type="button"
                                  className="btn btn-danger"
                                  onClick={() => handleRemoveRow(index)}
                                >
                                  Remove
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      ))}

                      <div className="text-start">
                        <button
                          type="button"
                          className="btn btnn org-btn text-light w-25"
                          onClick={handleAddRow}
                        >
                          Add Row
                        </button>
                      </div>

                      <div className="text-center mt-3">
                        <button type="submit" className="btn btnn org-btn text-light w-50">Update</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer /> {/* Add ToastContainer to your component tree */}
    </div>
  );
};

export default UpdateAgenda;
