// src/axiosInstance.js
import axios from 'axios';

const axiosInstance = axios.create({
  // baseURL: 'http://localhost:5000/api',  
  baseURL: 'https://nodeback.evventoz.com/api/', 
  headers: {
    'Content-Type': 'application/json',
  },
});

export default axiosInstance;