import React, { useEffect, useState, useRef } from 'react';
import axiosInstance from '../../axiosInstance';
import { useEvent } from '../../contexts/EventContext';
import { Link, useParams } from 'react-router-dom';
import { saveAs } from 'file-saver'; // For downloading Excel files
import * as XLSX from 'xlsx'; // For creating Excel files
import { toast, ToastContainer } from 'react-toastify'; // Import react-toastify
import 'react-toastify/dist/ReactToastify.css'; // Import react-toastify styles
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'; 

const EventSponsor = () => {
  const { eventId } = useEvent(); // Get the eventId from context
  const [sponsors, setSponsors] = useState([]);
  const [filteredSponsors, setFilteredSponsors] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedItemsPerPage, setSelectedItemsPerPage] = useState('10');
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const dropdownRef = useRef(null);
  const { event_unique_id } = useParams(); // Use event_unique_id from URL parameters

  useEffect(() => {
    document.title = 'Sponsor Details'; // Set the page title here
    if (eventId) {
      fetchSponsors(); // Fetch sponsors if eventId is available
    }
  }, [eventId]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const fetchSponsors = async () => {
    try {
      const response = await axiosInstance.get(`/fetch_sponser/${eventId}`);
      setSponsors(response.data.data);
      setFilteredSponsors(response.data.data);
    } catch (error) {
      console.error('Error fetching sponsors:', error);
    }
  };

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = sponsors.filter(sponsor =>
      sponsor.event_sponser_name.toLowerCase().includes(query) ||
      sponsor.event_sponser_website.toLowerCase().includes(query)
    );
    setFilteredSponsors(filtered);
    setCurrentPage(1); // Reset to first page on search
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(Number(value));
    setSelectedItemsPerPage(value);
    setCurrentPage(1); // Reset to first page when items per page changes
    setIsDropdownOpen(false); // Close the dropdown after selection
  };

  const getPaginatedData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredSponsors.slice(startIndex, endIndex);
  };

  const totalPages = Math.ceil(filteredSponsors.length / itemsPerPage);

  const handleCheckboxChange = (id) => {
    setSelectedRows(prevState =>
      prevState.includes(id) ? prevState.filter(item => item !== id) : [...prevState, id]
    );
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      setSelectedRows(filteredSponsors.map(sponsor => sponsor.id));
    }
    setSelectAll(!selectAll);
  };

  const handleExport = () => {
    const selectedData = sponsors.filter(sponsor => selectedRows.includes(sponsor.id));

    if (selectedData.length === 0) {
      toast.warning('Please select the rows to export.'); // Show warning toast if no rows are selected
      return;
    }

    // Filter to include only specific fields
    const exportData = selectedData.map(({ 
      event_sponser_name, 
      event_sponser_title, 
      event_sponser_website, 
      event_sponser_image 
    }) => ({
      Name: event_sponser_name,
      Title: event_sponser_title,
      Website: event_sponser_website,
      Image: event_sponser_image
    }));

    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sponsors');
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), 'sponsors.xlsx');
  };

  return (
    <div className="container-dash p-0 position-relative">
      <div className="background-overlay"></div>
      <div className="d-flex justify-content-center align-items-center">
        <div className="col-md-12 d-flex align-items-center justify-content-center">
          <div className="container my-3">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card shadow-lg rounded">
                  <div className="card-body p-4">
                    <div className="d-flex justify-content-between align-items-center mb-4">
                     
                      <h3 className="text-start h3txt">Sponsor Details</h3>
                      <Link
                        to={`/events/${event_unique_id}/add-sponser`} // Link to the Add Sponsors page
                        className="btn btn-primary"
                      >
                        Add Sponsors
                      </Link>
                    </div>
                    <div className="row mb-4">
                    <div className="col-2">
  <div className="custom-select-dropdown" ref={dropdownRef}>
    <div className="input-container" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
      <input
        type="text"
        className="form-control"
        value={selectedItemsPerPage}
        readOnly
      />
      <FontAwesomeIcon icon={faChevronDown} className="dropdown-icon" />
    </div>
    {isDropdownOpen && (
      <div className="dropdown-menu">
        {[10, 25, 50, 100, 500].map((num) => (
          <div
            key={num}
            className="dropdown-item"
            onClick={() => handleItemsPerPageChange(num)}
          >
            {num}
          </div>
        ))}
      </div>
    )}
  </div>
</div>
                      <div className="col-10 d-flex align-items-center">
                        <input
                          type="text"
                          className="form-control me-2"
                          placeholder="Search by name or website"
                          value={searchQuery}
                          onChange={handleSearch}
                        />
                        <button
                          className="btn btn-success ms-2"
                          onClick={handleExport}
                        >
                          Export
                        </button>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table  mt-3">
                        <thead>
                          <tr>
                            <th>
                              <input
                                type="checkbox"
                                checked={selectAll}
                                onChange={handleSelectAllChange}
                              />
                            </th>
                            {/* <th>ID</th> */}
                            <th>Name</th>
                            <th>Title</th>
                            <th>Website</th>
                            <th>Image</th>
                          </tr>
                        </thead>
                        <tbody>
                          {getPaginatedData().length > 0 ? (
                            getPaginatedData().map((sponsor) => (
                              <tr key={sponsor.id}>
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={selectedRows.includes(sponsor.id)}
                                    onChange={() => handleCheckboxChange(sponsor.id)}
                                  />
                                </td>
                                {/* <td>{sponsor.id}</td> */}
                                <td>{sponsor.event_sponser_name}</td>
                                <td>{sponsor.event_sponser_title}</td>
                                <td>
                                  <a
                                    href={sponsor.event_sponser_website}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {sponsor.event_sponser_website}
                                  </a>
                                </td>
                                <td>
                                  <img
                                    src={`/api/uploads/sponser/${sponsor.event_sponser_image}`}
                                    alt={sponsor.event_sponser_name}
                                    style={{ width: '100px', height: 'auto' }}
                                  />
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="6" className="text-center">
                                No sponsors found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-4">
                      <div>
                        Showing {Math.min((currentPage - 1) * itemsPerPage + 1, filteredSponsors.length)} - {Math.min(currentPage * itemsPerPage, filteredSponsors.length)} of {filteredSponsors.length} results
                      </div>
                      <div>
                        <ul className="pagination">
                          {Array.from({ length: totalPages }, (_, index) => (
                            <li
                              key={index}
                              className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}
                            >
                              <button
                                className="page-link"
                                onClick={() => handlePageChange(index + 1)}
                              >
                                {index + 1}
                              </button>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer /> {/* Toastr container for showing notifications */}
    </div>
  );
};

export default EventSponsor;
