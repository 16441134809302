import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from 'react-bootstrap';
import FirstSidebarOrganiser from "../Component/FirstSidebarOrganiser";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import BASE_URL from "../Component/Baseurl";
import Swal from 'sweetalert2';

const UserAccess = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Use the useNavigate hook
  const organiserId = localStorage.getItem("organiser_id");

  // Check if organiserId is available, if not redirect to home page
  useEffect(() => {
    if (!organiserId) {
      navigate('/'); // Redirect to home page
    }
  }, [organiserId, navigate]);

  useEffect(() => {
    const fetchUserAccess = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/user-access/${organiserId}`);
        setUsers(response.data.data);
      } catch (err) {
        console.error("Error fetching user access:", err);
        setError("Failed to fetch user access records.");
        toast.error("Failed to fetch user access records.");
      } finally {
        setLoading(false);
      }
    };

    if (organiserId) {
      fetchUserAccess();
    }
  }, [organiserId]);

  const handleDelete = async (userId) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!'
    });

    if (result.isConfirmed) {
      console.log("Deleting user with ID:", userId);
      try {
        const response = await axios.delete(`${BASE_URL}/user-access-delete/${userId}`); 
        setUsers(users.filter(user => user.id !== userId)); // Remove the deleted user from state
        toast.success(response.data.message);
      } catch (err) {
        console.error("Error deleting user access:", err);
        toast.error("Failed to delete user access record.");
      }
    }
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <>
      <ToastContainer />
      <FirstSidebarOrganiser />
      <div className="container-dash p-0 position-relative">
        <div className="background-overlay"></div>
        <div className="d-flex justify-content-center align-items-center">
          <div className="col-md-12 d-flex align-items-center justify-content-center">
            <div className="container my-3">
              <div className="row justify-content-center">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="card shadow-lg rounded">
                    <div className="card-body p-4">
                      <div className="d-flex justify-content-between align-items-center mb-4">
                        <h3 className="text-start h3txt">User Details</h3>
                        <Link to={`/add-access`} className="btn btn-primary">Add</Link>
                      </div>

                      <div className="table-responsive">
                        <table className="table mt-3">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Email</th>
                              <th>Phone</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {users.length > 0 ? (
                              users.map((user) => (
                                <tr key={user.id}>
                                  <td>{user.name}</td>
                                  <td>{user.email}</td>
                                  <td>{user.phone}</td>
                                  <td>
                                    <button className="btn btn-primary">Edit</button>
                                    <button className="btn btn-danger ms-2" onClick={() => handleDelete(user.id)}>Delete</button>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="4" className="text-center">No users found</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserAccess;
