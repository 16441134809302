// src/Organiser/PrePayment.js
import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { Link, Navigate, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import FirstSidebarOrganiser from "../Component/FirstSidebarOrganiser";
import OrgTopBar from "../Component/OrgTopBar";
import axios from "axios";
import BASE_URL from "../Component/Baseurl";
import PayButton from "../Component/PayButton"; // Import PayButton

const PrePayment = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Fetch and decrypt parameters from URL
  const decrypt = (data) => atob(data); // Example: base64 decoding

  const currency = queryParams.get("currency") ? decrypt(queryParams.get("currency")) : "INR";
  const packageId = queryParams.get("packageId") ? decrypt(queryParams.get("packageId")) : null;
  const amount = queryParams.get("amount") ? decrypt(queryParams.get("amount")) : null;
  const packageName = queryParams.get("packageName") ? decrypt(queryParams.get("packageName")) : null;
  const billingCycle = queryParams.get("billingCycle") ? decrypt(queryParams.get("billingCycle")) : "monthly"; // default to monthly

  useEffect(() => {
    const checkAuthentication = () => {
      const randomToken = localStorage.getItem("random_token");
      const organiserId = localStorage.getItem("organiser_id");
      setIsAuthenticated(randomToken && organiserId ? true : false);
    };
    checkAuthentication();
  }, []);

  useEffect(() => {
    const fetchPackageDetails = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/fetch-package-details`);
        const foundPackage = response.data.data.find((pkg) => pkg.packageId === packageId);
        setSelectedPackage(foundPackage);
      } catch (error) {
        console.error("Error fetching package details:", error);
      }
    };
    fetchPackageDetails();
  }, [packageId]);

  if (isAuthenticated === false) {
    return <Navigate to="/Login" />;
  }
  const cleanedAmount = currency === "INR" ? amount.replace(/[^\d]/g, "") : amount.replace(/[^\d]/g, "");
  return (
    <>
      <ToastContainer />
      <FirstSidebarOrganiser />

      <div className="container-dash p-0 position-relative">
        <OrgTopBar />
        <div className="background-overlay"></div>

        <div className="d-flex justify-content-center align-items-center">
          <div className="col-md-12 d-flex align-items-center justify-content-center">
            <div className="container my-3">
              <div className="row justify-content-center">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="card shadow-lg rounded nwbg-card">
                    <h2 className="text-center mb-3 heading-h2 mt-2">Complete Your Purchase</h2>

                    <div className="px-4">
                      <div className="row mx-4 card">
                        <div className="col">
                          <div className="text-center mb-3">
                            <h4>Selected Package: {packageName}</h4>
                            <h4>Billing Cycle: {billingCycle}</h4>
                            <h4>Selected Currency: {currency}</h4>
                            {packageId && <h4>Selected Package ID: {packageId}</h4>}
                            <h4>
      Amount: {currency === "INR" ? `₹${cleanedAmount}` : `${cleanedAmount}`}
    </h4>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Checkout button */}
                    <div className="text-center my-4">
                      <PayButton
                        packageId={packageId}
                        currency={currency}
                        amount={cleanedAmount}
                        billingCycle={billingCycle}
                        packageName={packageName}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrePayment;
