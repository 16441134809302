import React, { useState, useEffect } from 'react';
import { useEvent } from '../../contexts/EventContext'; // Assumed event context
import axiosInstance from '../../axiosInstance'; // Axios instance to handle API requests
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify'; // Import react-toastify
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup, faFile, faTrash, faChevronUp, faChevronDown, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import OrgTopBar from "../OrgTopBar";

const EventTimeZone = ({ initialRows = [] }) => {
  const { eventId } = useEvent(); // Get eventId from EventContext
  const [timeZones] = useState([
    'America/New_York',
    'Europe/London',
    'Asia/Tokyo',
    'Australia/Sydney',
    'Asia/Kolkata',      // India Standard Time
    'Asia/Singapore',    // Singapore Standard Time
    'Asia/Dhaka',        // Bangladesh Standard Time (South Asia)
    'Asia/Karachi',      // Pakistan Standard Time (South Asia)
    'Asia/Kathmandu',    // Nepal Time (South Asia)
]);


  const [eventStartDate, setEventStartDate] = useState('');
  const [eventEndDate, setEventEndDate] = useState('');
  const [dates, setDates] = useState([]);

  const [rows, setRows] = useState(initialRows.length > 0 ? initialRows : [
    {
      timezone: 'America/New_York',
      date: '',
      starttime: '00:00',
      endtime: '00:00',
    },
  ]);

  // Fetch event time data from backend
  const fetchEventTimeData = async () => {
    try {
      if (eventId) {
        // Fetch event dates
        const response = await axiosInstance.get(`/event_dates_by_eventID/${eventId}`);
        const { event_start_date, event_end_date } = response.data;

        setEventStartDate(event_start_date);
        setEventEndDate(event_end_date);

        // Generate dates range between start and end dates
        const startDate = new Date(event_start_date);
        const endDate = new Date(event_end_date);
        const dateArray = [];

        for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
          dateArray.push(new Date(d).toISOString().split('T')[0]);
        }

        setDates(dateArray);

        // Fetch and set the event time slots if any exist
        const eventTimeResponse = await axiosInstance.get(`/fetch_event_time_data/${eventId}`);
        if (eventTimeResponse.data.rows) {
          setRows(eventTimeResponse.data.rows);
        } else {
          toast.info('No time slots available, using default.');
        }
      } else {
        // toast.error('No event ID available');
      }
    } catch (error) {
      // toast.error('Error fetching event data');
      console.error('Error fetching event data:', error);
    }
  };

  useEffect(() => {
    fetchEventTimeData();
  }, [eventId]);

  const handleTimeChange = (event, rowIndex, type) => {
    const time = event.target.value;
    const updatedRows = rows.map((row, index) => {
      if (index === rowIndex) {
        return {
          ...row,
          [type]: time,
        };
      }
      return row;
    });
    setRows(updatedRows);
  };

  const handleAddRow = () => {
    setRows([
      ...rows,
      {
        timezone: 'America/New_York',
        date: dates[0],
        starttime: '00:00',
        endtime: '00:00',
      },
    ]);
  };

  const handleRemoveRow = (index) => {
    if (rows.length > 1) {
      const updatedRows = rows.filter((_, rowIndex) => rowIndex !== index);
      setRows(updatedRows);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axiosInstance.put(`/updateForm2data`, {
        eventId,
        rows,
      });

      toast.success('Data submitted successfully');
      console.log('Data submitted successfully:', response.data);
    } catch (error) {
      toast.error('Error submitting data');
      console.error('Error submitting data:', error);
    }
  };

  return (
    <div className="container-dash p-0 position-relative">
      <OrgTopBar />
      <div className="background-overlay"></div>
      <div className="d-flex justify-content-center align-items-center">
        <div className="col-md-12 d-flex align-items-center justify-content-center">
          <div className="container my-3">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12 col-sm-12">

              <div class="headingcard align-content-center gradient-border py-1 mb-2">
                  <h1 class="text-start text-white px-3 titleh1">
                    Event Overview
                  </h1>
                  <p class="text-start text-white px-3 pb-0 ">
                    Overview of Event
                  </p>
                </div>


                <div className="card shadow-lg rounded">
                  <div className="card-body p-3">
                     

                    <div className="row">
                    <div className="col-md-3 text-start">
                    <div className="card p-2">
                                  <Link
                                    to={`/events/${eventId}`}
                                    className="  dftxt"
                                  >
                                    <div className="evntovr ">
                                      Event Basic Details{" "}
                                    </div>
                                  </Link>

                                  <Link
                                    to={`/events/${eventId}/EventTimeZone`}
                                    className=" activetxt dftxt"
                                  >
                                    <div className="evntovr activecnt">
                                      Event Time Details
                                    </div>
                                  </Link>

                                  <Link
                                    to={`/events/${eventId}/eventvenue`}
                                    className="  dftxt"
                                  >
                                    <div className="evntovr">
                                      Event Venue Details
                                    </div>
                                  </Link>

                                  <Link
                                    to={`/events/${eventId}/add-event-faq`}
                                    className="dftxt "
                                  >
                                    <div className="evntovr  ">Event FAQS</div>
                                  </Link>
                                </div>
                              
                            </div>
                      <div className="col-md-9">

<div className="text-end mb-2">
<button
                            type="button"
                            className="text-light shotbtn p-1 bg-gradient-info"
                            onClick={handleAddRow}
                          >
                            Add Time Slot
                          </button>
</div>

                        <form onSubmit={handleSubmit}>
                          {rows.map((row, rowIndex) => (
                            <div className="row text-start mb-4 rowclbd" key={rowIndex} id="repeat">
                              <div className="col-md-3">
                                <div className="form-group">
                                  <label className="text-dark">Timezone</label>
                                  <select
                                    className="form-control inputgroup2"
                                    style={{ color: 'black' }}
                                    value={row.timezone || 'America/New_York'}
                                    onChange={(e) => {
                                      const updatedRows = rows.map((r, index) =>
                                        index === rowIndex ? { ...r, timezone: e.target.value } : r
                                      );
                                      setRows(updatedRows);
                                    }}
                                  >
                                    {timeZones.map((zone, index) => (
                                      <option key={index} value={zone}>
                                        {zone}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>

                              <div className="col-md-3">
                                <div className="form-group">
                                  <label className="text-dark">Select Date</label>
                                  <select
                                    className="form-control inputgroup2"
                                    style={{ color: 'black' }}
                                    value={row.date || dates[0]}
                                    onChange={(e) => {
                                      const updatedRows = rows.map((r, index) =>
                                        index === rowIndex ? { ...r, date: e.target.value } : r
                                      );
                                      setRows(updatedRows);
                                    }}
                                  >
                                    {dates.map((date, index) => (
                                      <option key={index} value={date}>
                                        {new Date(date).toLocaleDateString()}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>

                              <div className="col-md-2">
                                <div className="form-group">
                                  <label className="text-dark">Select Start Time</label>
                                  <input
                                    type="time"
                                    value={row.starttime || '00:00'}
                                    onChange={(e) => handleTimeChange(e, rowIndex, 'starttime')}
                                    className="form-control inputgroup2"
                                  />
                                </div>
                              </div>

                              <div className="col-md-2">
                                <div className="form-group">
                                  <label className="text-dark">Select End Time</label>
                                  <input
                                    type="time"
                                    value={row.endtime || '00:00'}
                                    onChange={(e) => handleTimeChange(e, rowIndex, 'endtime')}
                                    className="form-control inputgroup2"
                                  />
                                </div>
                              </div>

                              <div className="col-md-2">
                                <label className="text-dark">Action</label>
                                <br />
                                <div>
                                  {rows.length > 1 && (
                                    <div
                                      
                                      className="nav_icondelete text-center w-50"
                                      onClick={() => handleRemoveRow(rowIndex)}
                                    >
                                      <FontAwesomeIcon icon={faTrash} className='nav_iconwht' />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}

                          

                          <div className="text-end my-3">
                            <button
                              type="submit"
                              className="btnn org-btn text-light w-25"
                            >
                              Save Changes
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer /> {/* Add ToastContainer here */}
    </div>
  );
};

export default EventTimeZone;
