import React, { useEffect, useState } from 'react';
import axiosInstance from '../../axiosInstance';
import { useEvent } from '../../contexts/EventContext';
import { toast, ToastContainer } from 'react-toastify';
import { useDropzone } from 'react-dropzone';
import 'react-toastify/dist/ReactToastify.css'; // Import the styles for react-toastify 
import OrgTopBar from '../OrgTopBar';

const EventImage = () => {
  const { eventId } = useEvent();
  const [bannerImage, setBannerImage] = useState(null);
  const [fullBannerImage, setFullBannerImage] = useState(null);
  const [floorPlanImage, setFloorPlanImage] = useState(null);
  const [brochurePdf, setBrochurePdf] = useState(null);
  const [uploading, setUploading] = useState(false); // State for uploading status
  const [uploadProgress, setUploadProgress] = useState(0); // State for upload progress

  useEffect(() => {
    document.title = 'Upload Event Files'; // Set the page title here
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    if (bannerImage) formData.append('bannerImage', bannerImage);
    if (fullBannerImage) formData.append('fullBannerImage', fullBannerImage);
    if (floorPlanImage) formData.append('floorPlanImage', floorPlanImage);
    if (brochurePdf) formData.append('brochurePdf', brochurePdf);

    formData.append('eventUniqueId', eventId); // Append eventUniqueId

    try {
      setUploading(true); // Set uploading state to true
      setUploadProgress(0); // Reset upload progress
      
      const response = await axiosInstance.put('/Event_image_upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted); // Update upload progress
        },
      });

      console.log('Upload success:', response.data);
      toast.success('Files uploaded successfully!'); // Show success message
    } catch (error) {
      console.error('Upload error:', error);
      toast.error('Upload failed! Please try again.'); // Show error message
    } finally {
      setUploading(false); // Reset uploading state after upload is complete
      setUploadProgress(0); // Reset upload progress
    }
  };

  const validateFileSize = (file, maxSize) => {
    if (file.size > maxSize) {
      return false;
    }
    return true;
  };

  const onDropBanner = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (validateFileSize(file, 1000 * 1024)) { // 500 KB for images
      setBannerImage(file); // Update banner image
    } else {
      toast.error('Banner image size exceeds 500 KB!'); // Show error message
    }
  };

  const onDropFullBanner = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (validateFileSize(file, 500 * 1024)) { // 500 KB for images
      setFullBannerImage(file); // Update full banner image
    } else {
      toast.error('Full banner image size exceeds 500 KB!'); // Show error message
    }
  };

  const onDropFloorPlan = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (validateFileSize(file, 500 * 1024)) { // 500 KB for images
      setFloorPlanImage(file); // Update floor plan image
    } else {
      toast.error('Floor plan image size exceeds 500 KB!'); // Show error message
    }
  };

  const onDropBrochure = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (validateFileSize(file, 2 * 1024 * 1024)) { // 2 MB for PDF
      setBrochurePdf(file); // Update brochure PDF
    } else {
      toast.error('Brochure PDF size exceeds 2 MB!'); // Show error message
    }
  };

  const removeBannerImage = () => setBannerImage(null);
  const removeFullBannerImage = () => setFullBannerImage(null);
  const removeFloorPlanImage = () => setFloorPlanImage(null);
  const removeBrochurePdf = () => setBrochurePdf(null);

  const { getRootProps: getBannerRootProps, getInputProps: getBannerInputProps } = useDropzone({
    onDrop: onDropBanner,
    accept: {
      'image/*': ['.jpeg', '.png', '.gif'],
    },
  });

  const { getRootProps: getFullBannerRootProps, getInputProps: getFullBannerInputProps } = useDropzone({
    onDrop: onDropFullBanner,
    accept: {
      'image/*': ['.jpeg', '.png', '.gif'],
    },
  });

  const { getRootProps: getFloorPlanRootProps, getInputProps: getFloorPlanInputProps } = useDropzone({
    onDrop: onDropFloorPlan,
    accept: {
      'image/*': ['.jpeg', '.png', '.gif'],
    },
  });

  const { getRootProps: getBrochureRootProps, getInputProps: getBrochureInputProps } = useDropzone({
    onDrop: onDropBrochure,
    accept: {
      'application/pdf': ['.pdf'],
    },
  });

  return (
    <div className="container-dash p-0 position-relative">

<OrgTopBar/>

      <div className="background-overlay"></div>
      <div className="d-flex justify-content-center align-items-center">
        <div className="col-md-12 d-flex align-items-center justify-content-center">
          <div className="container my-3">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12 col-sm-12">

              <div class="headingcard align-content-center gradient-border py-1 mb-2">
                  <h1 class="text-start text-white px-3 titleh1">
                  Upload Event Files
                  </h1>
                  <p class="text-start text-white px-3 pb-0 ">
                    Update Event Image, Banner Image, Floor Plan, Event Brochure.
                  </p>
                </div>

                
                <div className="card shadow-lg rounded">
                  <div className="card-body p-3">  
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        {/* Event Banner Image */}
                        <div className="col-md-3 mb-3">
                          <label className="form-label">Event Banner Image</label>
                          <div {...getBannerRootProps({ className: 'dropzone' })}>
                            <input {...getBannerInputProps()} />
                            <p>Drag & drop some files here, or click to select files </p>
                            <p className='smlcls'>[max file size is upto 500kb and Resolution : 1440 x 720 px]</p>
                          </div>
                          {bannerImage && (
                            <div className="preview-container mt-2">
                              <img 
                                src={URL.createObjectURL(bannerImage)} 
                                alt="Banner Preview" 
                                className="img-fluid" 
                                style={{ maxHeight: '100px' }} 
                              />
                              <button 
                                type="button" 
                                onClick={removeBannerImage} 
                                className="btn btn-danger btn-sm ms-2"
                                title="Remove"
                              >
                                ✖
                              </button>
                            </div>
                          )}
                        </div>

                        {/* Event Full Banner Image */}
                        <div className="col-md-3 mb-3">
                          <label className="form-label">Full Banner Image</label>
                          <div {...getFullBannerRootProps({ className: 'dropzone' })}>
                            <input {...getFullBannerInputProps()} />
                            <p>Drag & drop some files here, or click to select files</p>
                            <p className='smlcls'>[max file size is upto 500kb and Resolution : 1440 x 720 px]</p>
                          </div>
                          {fullBannerImage && (
                            <div className="preview-container mt-2">
                              <img 
                                src={URL.createObjectURL(fullBannerImage)} 
                                alt="Full Banner Preview" 
                                className="img-fluid" 
                                style={{ maxHeight: '100px' }} 
                              />
                              <button 
                                type="button" 
                                onClick={removeFullBannerImage} 
                                className="btn btn-danger btn-sm ms-2"
                                title="Remove"
                              >
                                ✖
                              </button>
                            </div>
                          )}
                        </div>

                        {/* Event Floor Plan Image */}
                        <div className="col-md-3 mb-3">
                          <label className="form-label">Floor Plan Image</label>
                          <div {...getFloorPlanRootProps({ className: 'dropzone' })}>
                            <input {...getFloorPlanInputProps()} />
                            <p>Drag & drop some files here, or click to select files</p>
                            <p className='smlcls'>[max file size is upto 500kb and Resolution : 1440 x 720 px]</p>
                          </div>
                          {floorPlanImage && (
                            <div className="preview-container mt-2">
                              <img 
                                src={URL.createObjectURL(floorPlanImage)} 
                                alt="Floor Plan Preview" 
                                className="img-fluid" 
                                style={{ maxHeight: '100px' }} 
                              />
                              <button 
                                type="button" 
                                onClick={removeFloorPlanImage} 
                                className="btn btn-danger btn-sm ms-2"
                                title="Remove"
                              >
                                ✖
                              </button>
                            </div>
                          )}
                        </div>

                        {/* Event Brochure PDF */}
                        <div className="col-md-3 mb-3">
                          <label className="form-label">Event Brochure (PDF)</label>
                          <div {...getBrochureRootProps({ className: 'dropzone' })}>
                            <input {...getBrochureInputProps()} />
                            <p>Drag & drop a PDF file here, or click to select files</p>
                            <p className='smlcls'>[max file size is upto 500kb and Resolution : 1440 x 720 px]</p>
                          </div>
                          {brochurePdf && (
                            <div className="preview-container mt-2">
                              <p>PDF: {brochurePdf.name}</p>
                              <button 
                                type="button" 
                                onClick={removeBrochurePdf} 
                                className="btn btn-danger btn-sm ms-2"
                                title="Remove"
                              >
                                ✖
                              </button>
                            </div>
                          )}
                        </div>
                      </div>

                      {/* Upload Status */}
                      {uploading && (
                        <div className="mt-3">
                          <p>Uploading... {uploadProgress}%</p>
                          <div className="progress">
                            <div 
                              className="progress-bar" 
                              role="progressbar" 
                              style={{ width: `${uploadProgress}%` }} 
                              aria-valuenow={uploadProgress} 
                              aria-valuemin="0" 
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </div>
                      )}

                      <button type="submit" className="btn btnn org-btn text-light w-25">Upload Files</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer /> {/* Add the ToastContainer for notifications */}
    </div>
  );
};

export default EventImage;
