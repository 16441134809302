import React, { useState, useEffect } from "react";
import { useEvent } from "../../contexts/EventContext"; // Assumed event context
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from "react-router-dom";
import axiosInstance from '../../axiosInstance'; // Axios instance to handle API requests
import OrgTopBar from "../OrgTopBar";
const EventVenue = () => {
  const { eventId } = useEvent();
  const [eventMode, setEventMode] = useState("physical");
  const [venue, setVenue] = useState("");
  const [venues, setVenues] = useState([]); // State to store venue list
  const [timezone, setTimezone] = useState("");
  const [joiningLink, setJoiningLink] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false); 

  // Fetch venue details by event ID
  useEffect(() => {
    const fetchVenueDetails = async () => {
      try {
        const response = await axiosInstance.get(`/fetch_event_venue_by_event_id/${eventId}`);
        const { eventVenue } = response.data;

        // Populate state with fetched data or set defaults if not found
        if (eventVenue) {
          // Map event_mode to the component's state
          switch (eventVenue.event_mode) {
            case "In-Person":
              setEventMode("physical");
              break;
            case "Hybrid":
              setEventMode("hybrid");
              break;
            case "Virtual":
              setEventMode("virtual");
              break;
            default:
              setEventMode("physical"); // Default to physical if undefined
          }

          setVenue(eventVenue.venue || "");
          setTimezone(eventVenue.timezone || "");
          setJoiningLink(eventVenue.joining_link || "");
        } else {
          // If no event venue found, set default values
          setEventMode("physical");
          setVenue("");
          setTimezone("");
          setJoiningLink("");
        }
      } catch (error) {
        console.error("Error fetching venue details:", error.response ? error.response.data : error.message);
      }
    };

    const fetchAllVenues = async () => {
      try {
        const response = await axiosInstance.get('/all-venue-list'); // Fetch all venues
        const { venues } = response.data; // Adjust based on your API response structure
        setVenues(Array.isArray(venues) ? venues : []); // Ensure venues is an array
      } catch (error) {
        console.error("Error fetching venues:", error.response ? error.response.data : error.message);
        toast.error("Failed to fetch venues.");
      }
    };

    fetchVenueDetails();
    fetchAllVenues();
  }, [eventId]); // Run effect when eventId changes

  const handleEventModeChange = (e) => {
    setEventMode(e.target.value);
  };

  // Update event function
  const handleUpdateEvent = async (e) => {
    e.preventDefault();

    // Prepare the payload for the API request
    const payload = {
      event_unique_id: eventId,
      venue,
      timezone,
      joining_link: joiningLink,
      event_mode: eventMode,
    };

    try {
      const response = await axiosInstance.post('/update_event_venue', payload);
      toast.success("Event venue updated successfully!");
    } catch (error) {
      console.error("Error updating event venue:", error.response ? error.response.data : error.message);
      toast.error("Error updating event venue.");
    }
  };

  // Find selected venue object
  const selectedVenue = venues.find(v => v.id === venue);

  return (
    <div className="container-dash p-0 position-relative">
      <OrgTopBar />
      <div className="background-overlay"></div>
      <div className="d-flex justify-content-center align-items-center">
        <div className="col-md-12 d-flex align-items-center justify-content-center">
          <div className="container my-3">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="headingcard align-content-center gradient-border py-1 mb-2">
                  <h1 className="text-start text-white px-3 titleh1">
                  Event Venue Details
                  </h1>
                  <p className="text-start text-white px-3 pb-0 ">
                  Specify your event venue details below
                  </p>
                </div>

                <div className="card shadow-lg rounded">
                  <div className="card-body p-3">
                  
                    <div className="tab-content" id="myTabContent">
                      <div className="tab-pane fade show active w-100" id="home" role="tabpanel">
                        {error ? (
                          <p className="text-danger">{error}</p>
                        ) : (
                          <form onSubmit={handleUpdateEvent}>
                            <div className="row text-start">
                              <div className="col-md-3 text-start">
                                <div className="card p-2">
                                  <Link to={`/events/${eventId}`} className="dftxt">
                                    <div className="evntovr">Event Basic Details</div>
                                  </Link>
                                  <Link to={`/events/${eventId}/EventTimeZone`} className="dftxt">
                                    <div className="evntovr">Event Time Details</div>
                                  </Link>
                                  <Link to={`/events/${eventId}/eventvenue`} className="activetxt dftxt">
                                    <div className="evntovr activecnt">Event Venue Details</div>
                                  </Link>
                                  <Link to={`/events/${eventId}/add-event-faq`} className="dftxt">
                                    <div className="evntovr">Event FAQS</div>
                                  </Link>
                                </div>
                              </div>

                              <div className="col-md-9">
                                <div className="form-group mb-3">
                                  <label>Event Mode</label>
                                  <div>
                                    <label className="me-3">
                                      <input
                                        type="radio"
                                        value="physical"
                                        checked={eventMode === "physical"}
                                        onChange={handleEventModeChange}
                                        disabled={eventMode !== "physical"} // Disable if not selected
                                      /> Physical
                                    </label>
                                    <label className="me-3">
                                      <input
                                        type="radio"
                                        value="virtual"
                                        checked={eventMode === "virtual"}
                                        onChange={handleEventModeChange}
                                        disabled={eventMode !== "virtual"} // Disable if not selected
                                      /> Virtual
                                    </label>
                                    <label>
                                      <input
                                        type="radio"
                                        value="hybrid"
                                        checked={eventMode === "hybrid"}
                                        onChange={handleEventModeChange}
                                        disabled={eventMode !== "hybrid"} // Disable if not selected
                                      /> Hybrid
                                    </label>
                                  </div>
                                </div>

                                {/* Show Physical options */}
                                {(eventMode === "physical" || eventMode === "hybrid") && (
                                  <>
                                    <div className="form-group mb-3">
                                      <label>Venue</label>
                                      <select
                                        className="form-control"
                                        value={venue}
                                        onChange={(e) => setVenue(e.target.value)}
                                      >
                                        <option value="">Select Venue</option>
                                        {venues.map((v) => (
                                          <option key={v.id} value={v.id}>{v.venue_name}</option>
                                        ))}
                                      </select>
                                    </div>

                                    {/* Display embedded Google Map if venue is selected */}
                                    <div className="form-group mb-3">
                                      <label>Google Map</label>
                                      {selectedVenue && selectedVenue.venue_map && (
                                        <div dangerouslySetInnerHTML={{ __html: selectedVenue.venue_map }} />
                                      )}
                                    </div>
                                  </>
                                )}

                                {/* Show Virtual options */}
                                {(eventMode === "virtual" || eventMode === "hybrid") && (
                                  <>
                                    <div className="form-group mb-3">
                                      <label>Timezone</label>
                                      <select
                                        className="form-control"
                                        value={timezone}
                                        onChange={(e) => setTimezone(e.target.value)}
                                      >
                                        <option value="">Select Timezone</option>
                                        <option value="IST">IST (Indian Standard Time)</option>
                                        <option value="EST">EST (Eastern Standard Time)</option>
                                        <option value="CST">CST (Central Standard Time)</option>
                                        <option value="PST">PST (Pacific Standard Time)</option>
                                      </select>
                                    </div>

                                    <div className="form-group mb-3">
                                      <label>Joining Link</label>
                                      <input
                                        type="url"
                                        className="form-control"
                                        value={joiningLink}
                                        onChange={(e) => setJoiningLink(e.target.value)}
                                        placeholder="Enter Joining Link"
                                        required
                                      />
                                    </div>
                                  </>
                                )}

                                <div className="text-end">
                                <button type="submit" className="btnn org-btn text-light w-25">Save Changes</button>
                                </div>
                                
                              </div>
                            </div>
                          </form>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ToastContainer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventVenue;
