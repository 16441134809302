import React from 'react';
import axios from 'axios';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BASE_URL from '../Component/Baseurl';


const VerifyEmailOrganiser = () => {
  const handleResendEmail = () => {
    const email = localStorage.getItem('userEmail');
    const name = 'User'; // Replace with actual name if available

    if (!email) {
      toast.error('No email found in local storage.');
      return;
    }

    // Show initial toast message immediately
    toast.success('Email has been sent successfully.');

    // Proceed with the API call
    axios.post(`${BASE_URL}email-organiser-registration.php`, {
      email: email,
      name: name,
      token: email,
    }).then(emailVerificationResponse => {
      if (!emailVerificationResponse.data.success) {
        console.log('Email Verification Status: Failed');
        toast.error('Email verification failed.');
      } else {
        toast.success('Check your email for the verification link.');
        console.log(email);
      }
    }).catch(emailVerificationError => {
      console.error('Error verifying email:', emailVerificationError);
      toast.error('Email verification failed.');
    });
  };

  return (
    <>
      <ToastContainer />
      <div className="container-fluid p-0 position-relative">
        <div className="background-overlay"></div>
        <div className="background-image"></div>
        <div className="d-flex justify-content-center align-items-center min-vh-100">
          <div className="col-md-12 d-flex align-items-center justify-content-center">
            <div className="container my-3">
              <div className="row justify-content-center">
                <div className="col-lg-8 col-md-10 col-sm-12">
                  <div className="card shadow-lg rounded">
                    <div className="card-body p-5">
                      <h2 className="text-center mb-4">Account Created Successfully</h2>
                      <p className="text-center mb-4">
                        Thank you for registering! We have sent a verification email to your inbox. Please check your email and spam folder to complete the verification process.
                      </p>
                      <div className="text-center">
                        <button className="btn btn-primary" onClick={() => window.location.href = '/Org_login'}>
                          Go to Login
                        </button>
                      </div>
                      <div className="text-center mt-4">
                        <p>If you did not receive the email, click below to resend:</p>
                        <button className="btn btn-link" onClick={handleResendEmail}>
                          Resend Verification Email
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyEmailOrganiser;