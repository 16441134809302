import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BASE_URL from '../Component/Baseurl';
import { Link, Navigate } from 'react-router-dom';

const Login = () => {
  const [emailOrPhone, setEmailOrPhone] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [redirectToDashboard, setRedirectToDashboard] = useState(false);

  useEffect(() => {
    // Clear local storage when the component mounts
    localStorage.clear();
  }, []);

  // Function to generate a random token
  const generateToken = () => {
    return Math.random().toString(36).substr(2) + Date.now().toString(36);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${BASE_URL}organiser_login`, {
        emailOrPhone: emailOrPhone,
        password: password,
      });
  
      console.log('Login response:', response.data); // Log entire response for debugging
  
      if (response.data.success) {
        toast.success('Login successful!', { autoClose: 2000 }); // Show success toast for 2 seconds
  
        const { organiser } = response.data;
  
        // Ensure organiser and organiser.id are defined
        if (organiser && organiser.id) {
          const randomToken = generateToken();
          console.log('organiser_id:', organiser.id); // Log organiser_id
          console.log('Generated Token:', randomToken);
  
          localStorage.setItem('random_token', randomToken); // Store the generated token
          localStorage.setItem('organiser_id', organiser.id); 
          localStorage.setItem('organiser', JSON.stringify(organiser));
          
          // Handle successful login (set redirect flag after 2 seconds)
          setTimeout(() => {
            setRedirectToDashboard(true);
          }, 2000);
        } else {
          throw new Error('organiser_id is undefined');
        }
      } else {
        setError(response.data.message);
        toast.error(response.data.message);
      }
    } catch (err) {
      console.error('Error logging in:', err);
      setError('Network Error: Please check your connection and try again.');
      toast.error('Network Error: Please check your connection and try again.');
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // Redirect to dashboard if redirectToDashboard is true
  if (redirectToDashboard) {
    return <Navigate to="/dashboard" />;
  }

  
  return (
    <div className="container-fluid p-0 position-relative">
      <div className="background-overlay"></div>
      <div className="background-image"></div>
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className="col-md-12 d-flex align-items-center justify-content-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-10 col-sm-12">
                <div className="card shadow-lg rounded">
                  <div className="card-body p-5">
                    <h2 className="text-center mb-4">Organiser Login</h2>
                    <form onSubmit={handleLogin}>
                      <div className="row">
                        <div className="col-12">
                          <div className="mb-3 text-start">
                            <label htmlFor="email_or_phone">Email or Phone</label>
                            <input
                              type="text"
                              className="inputgroup w-100"
                              id="email_or_phone"
                              placeholder="Enter Email or Phone"
                              value={emailOrPhone}
                              onChange={(e) => setEmailOrPhone(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="mb-3 text-start">
                            <label htmlFor="mypassword">Password</label>
                            <input
                              type={showPassword ? 'text' : 'password'}
                              className="inputgroup w-100"
                              id="mypassword"
                              placeholder="Enter Password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              required
                            />
                            <div className="form-check mt-2">
                              <input
                                className="form-check-input inputgroup"
                                type="checkbox"
                                id="mypasswordToggle"
                                onClick={toggleShowPassword}
                              />
                              <label className="form-check-label" htmlFor="mypasswordToggle">Show Password</label>
                            </div>
                          </div>
                        </div>
                        <div className="text-end mb-2">
                          <Link to="/ForgotPassword">Forgot Password?</Link>
                        </div>
                        <div className="col-12">
                          <button className="btnnew w-100" type="submit">Login</button>
                        </div>
                      </div>
                    </form>
                    <div className="text-center mt-3">Don't Have an Account&nbsp;
                      <Link to="/" className="">Create Account</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Login;
