// src/baseurl.js
// const BASE_URL = "https://reactevent.ozytechsolutions.com/api/"; 
// const BASE_URL = "http://test.evventoz.com/app/api/"; 
// const IMAGE_URL = "http://test.evventoz.com/app/api/img/"; 
// const BASE_URL = "http://localhost/evvent_react/app/api/"; 
// const IMAGE_URL = "http://localhost/evvent_react/app/api/img/"; 
// const BASE_URL = "https://nodeback.evventoz.com/api/"; 
 
// const IMAGE_URL = "http://localhost:5000/uploads/"; 
const IMAGE_URL = "https://nodeback.evventoz.com/uploads/"; 

export default IMAGE_URL;