// src/Organiser/Event_dashboard.js
import React, { useEffect } from 'react';
import { useParams, Routes, Route, Outlet } from 'react-router-dom';
import NavbarOrganiser from "../Component/NavbarOrganiser";
import EventOverview from '../Component/EventsComponent/EventOverview';
import SessionAgenda from '../Component/EventsComponent/SessionAgenda';
import { useEvent } from '../contexts/EventContext';
import EventImage from '../Component/EventsComponent/EventImage';
import EventExibitor from '../Component/EventsComponent/EventExibitor';
import AddExhibitorsForm from '../Component/EventsComponent/AddExhibitorsForm';
import EventSpeaker from '../Component/EventsComponent/EventSpeaker';
import AddSpeakersForm from '../Component/EventsComponent/AddSpeakersForm';

import EventSponser from '../Component/EventsComponent/EventSponser';
import ScrollToTop from './ScrollToTop';

 import AddSponserForm from '../Component/EventsComponent/AddSponserForm';
import EventPartner from '../Component/EventsComponent/EventPartner';
import AddPartnerForm from '../Component/EventsComponent/AddPartnerForm';
import FormBuilder from '../Component/EventsComponent/FormBuilder';
import DisplayForm from '../Component/EventsComponent/DisplayForm';
import DelegateForm from '../Component/EventsComponent/DelegateForm';
import SponserForm from '../Component/EventsComponent/SponserForm';
import SpeakerForm from '../Component/EventsComponent/SpeakerForm';
import VisitorForm from '../Component/EventsComponent/VisitorForm';
import ExibitorForm from '../Component/EventsComponent/ExibitorForm';

import DelegateLeads from '../Component/EventsComponent/DelegateLeads';
import SpeakerLeads from '../Component/EventsComponent/SpeakerLeads';
import SponserLeads from '../Component/EventsComponent/SponserLeads';
import VisitorLeads from '../Component/EventsComponent/VisitorLeads';
import ExhibitorLeads from '../Component/EventsComponent/ExhibitorLeads';
import EventOverview2 from '../Component/EventsComponent/EventOverview2';
import EventTimeZone from '../Component/EventsComponent/EventTimeZone';
import EventVenue from '../Component/EventsComponent/EventVenue';
import RatingReviewEvent from '../Component/EventsComponent/RatingReviewEvent';

import CreateSession from '../Component/EventsComponent/CreateSession';
import UpdateAgenda from '../Component/EventsComponent/UpdateAgenda';
import EventFollow from '../Component/EventsComponent/EventFollow';
import EventCalander from '../Component/EventsComponent/EventCalander';
import EventAnalyticsRptOne from '../Component/EventsComponent/EventAnaylticsRptOne';

import AddEventFaq from '../Component/EventsComponent/AddEventFaq';

// import AllFaqList from '../Component/EventsComponent/AllFaqList';



const Event_dashboard = () => {
  const { event_unique_id } = useParams();
  const { setEventId } = useEvent();

  useEffect(() => {
    setEventId(event_unique_id);
  }, [event_unique_id, setEventId]);

  return (
    <>
      <NavbarOrganiser />
      <div className="App" id="body-pd">
      <ScrollToTop />

        <Routes>
          {/* <Route path="/" element={<EventOverview />} /> */}

          <Route path="/" element={<EventOverview2 />} />
          <Route path="/EventTimeZone" element={<EventTimeZone />} />
          <Route path="/eventvenue" element={<EventVenue />} />
          <Route path="/add-event-faq" element={<AddEventFaq />} />
          
          
          <Route path="session-and-agenda" element={<SessionAgenda />} />

          <Route path="event_image" element={<EventImage />} /> 
          <Route path="exibitors" element={<EventExibitor />} /> 
          <Route path="Add-Exhibitors" element={<AddExhibitorsForm />} /> 
          <Route path="speakers" element={<EventSpeaker />} /> 
          <Route path="add-speakers" element={<AddSpeakersForm />} /> 
          <Route path="sponser" element={<EventSponser />} /> 
          <Route path="add-sponser" element={<AddSponserForm />} /> 
          <Route path="partner" element={<EventPartner />} /> 
          <Route path="add-partner" element={<AddPartnerForm />} /> 
          <Route path="add-custom-form" element={<FormBuilder />} /> 
          <Route path="displayForm" element={<DisplayForm />} /> 
          <Route path="delegate-custom-form" element={<DelegateForm />} /> 
          <Route path="sponsor-custom-form" element={<SponserForm />} /> 
          <Route path="speaker-custom-form" element={<SpeakerForm />} /> 
          <Route path="visitor-custom-form" element={<VisitorForm />} /> 
          <Route path="exhibitor-custom-form" element={<ExibitorForm />} /> 


          <Route path="delegate-leads" element={<DelegateLeads />} /> 
          <Route path="speaker-leads" element={<SpeakerLeads />} /> 
          <Route path="sponser-leads" element={<SponserLeads />} /> 
          <Route path="visitor-leads" element={<VisitorLeads />} /> 
          <Route path="exhibitor-leads" element={<ExhibitorLeads />} /> 


          <Route path="add-session" element={<CreateSession />} /> 
          <Route path="update-agenda/:session_id" element={<UpdateAgenda />} /> 
          
          <Route path="all-rating-review" element={<RatingReviewEvent />} /> 
          <Route path="event-follow" element={<EventFollow />} /> 
          <Route path="event-calander" element={<EventCalander />} /> 
          <Route path="analytics-report" element={<EventAnalyticsRptOne />} /> 
          
          
          
          {/* Add more nested routes as needed */}
        </Routes>
        {/* Render the matched route */}
        <Outlet />

         
      </div>
    </>
  );
};

export default Event_dashboard;
