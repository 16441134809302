import React, { useEffect, useState } from 'react';
import axiosInstance from '../../axiosInstance'; // Update this import according to your project structure

const DisplayForm = () => {
  const [formData, setFormData] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    // Function to fetch form data from the API
    const fetchFormData = async () => {
      const formId = 2; // Hardcoding formId to 2 for fetching this specific form
      console.log(`Attempting to fetch data for formId: ${formId}`);
      try {
        const response = await axiosInstance.get(`/fetch_custom_form/${formId}`);
        console.log('API hit successful', response.data); // Log API response

        if (response.data) {
          setFormData(response.data);
        } else {
          setError('No form data found.');
          console.warn('No form data returned from API');
        }
      } catch (err) {
        setError('Error fetching form data.');
        console.error('Error during API call:', err);
      }
    };

    // Fetch data for formId = 2
    fetchFormData();
  }, []);

  console.log('formData state:', formData); // Log formData to see if it's being set correctly

  if (error) return <p className="text-danger">{error}</p>;
  if (!formData) return <p>Loading...</p>;

  return (






    <div className="container-dash p-0 position-relative">
      <div className="background-overlay"></div>
      <div className="d-flex justify-content-center align-items-center">
        <div className="col-md-12 d-flex align-items-center justify-content-center">
          <div className="container my-3">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card shadow-lg rounded">
                  <div className="card-body p-3"> 
                    <div className="container">
      <h2 className="text-center mb-4">Form Details</h2>
      <div className="card p-4">
        <h3>{formData.formName}</h3>
        {formData.fields && formData.fields.length > 0 ? (
          <div>
            {formData.fields.map((field) => (
              <div key={field.id} className="mb-3 text-start">
                <label className="form-label ">
                  {field.name}
                  {field.required && <span className="text-danger">*</span>}
                </label>
                {field.type === 'text' && <input type="text" className="form-control" />}
                {field.type === 'textarea' && <textarea className="form-control" rows="3"></textarea>}
                {field.type === 'checkbox' && (
                  field.options && field.options.length > 0 &&
                  field.options.map((option, index) => (
                    <div key={index} className="form-check">
                      <input className="form-check-input" type="checkbox" id={`checkbox-${index}`} />
                      <label className="form-check-label" htmlFor={`checkbox-${index}`}>{option}</label>
                    </div>
                  ))
                )}
                {field.type === 'radio' && (
                  field.options && field.options.length > 0 &&
                  field.options.map((option, index) => (
                    <div key={index} className="form-check">
                      <input className="form-check-input" type="radio" name={field.name} id={`radio-${index}`} />
                      <label className="form-check-label" htmlFor={`radio-${index}`}>{option}</label>
                    </div>
                  ))
                )}
                {field.type === 'file' && <input type="file" className="form-control" />}
              </div>
            ))}
          </div>
        ) : (
          <p>No fields available for this form.</p>
        )}
      </div>
    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> 
      </div>
    </div>

    
  );
};

export default DisplayForm;