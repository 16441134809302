import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify"; // Import react-toastify
import "react-toastify/dist/ReactToastify.css"; // Import CSS for react-toastify
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import axiosInstance from "../../axiosInstance"; // Import the Axios instance
import { Link } from "react-router-dom";
import { useEvent } from "../../contexts/EventContext";
import AllFaqList from "./AllFaqList";
import OrgTopBar from "../OrgTopBar";

const AddEventFaq = () => {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { eventId } = useEvent(); // Get the eventId from the EventContext

  const handleAddFaq = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    if (!question || !answer) {
      toast.error("Please provide both question and answer.");
      setLoading(false);
      return;
    }

    const faqData = {
      question,
      answer,
      event_unique_id: eventId, // Send the event ID along with the FAQ
    };
    console.log("Sending FAQ data:", faqData); // Logs the data

    try {
      await axiosInstance.post("/add-faq", faqData);
      toast.success("FAQ added successfully! Check the below List");

      setQuestion("");
      setAnswer("");

      // Reload the page after 1 second
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.error(
        "Error adding FAQ:",
        error.response ? error.response.data : error.message
      );
      setError("An error occurred while adding the FAQ.");
      toast.error("Error adding FAQ.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-dash p-0 position-relative">
      <OrgTopBar />
      <div className="background-overlay"></div>
      <div className="d-flex justify-content-center align-items-center">
        <div className="col-md-12 d-flex align-items-center justify-content-center">
          <div className="container my-3">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12 col-sm-12">

              <div className="headingcard align-content-center gradient-border py-1 mb-2">
                  <h1 className="text-start text-white px-3 titleh1">
                  Add Event FAQ's
                  </h1>
                  <p className="text-start text-white px-3 pb-0 ">
                  Provide question and answer for the event FAQ
                  </p>
                </div>


                <div className="card shadow-lg rounded">
                  <div className="card-body p-3">
                    
                     
                    <div className="tab-content" id="myTabContent">
                      <div
                        className="tab-pane fade show active w-100"
                        id="home"
                        role="tabpanel"
                      >
                        {error && <p className="text-danger">{error}</p>}

                        <div className="row text-start">
                          <div className="col-md-3">
                            <div className="card p-2">
                              
                                <Link
                                  to={`/events/${eventId}`}
                                  className="text-dark dftxt"
                                >
                                  <div className="evntovr">
                                  Event Basic Details </div>
                                  
                                </Link>
                              
                              
                                <Link
                                  to={`/events/${eventId}/EventTimeZone`}
                                  className="text-dark dftxt"
                                ><div className="evntovr"> 
                                  Event Time Details</div>
                                </Link>
                             
                              
                                <Link
                                  to={`/events/${eventId}/eventvenue`}
                                  className="text-dark dftxt"
                                ><div className="evntovr">
                                  Event Venue Details</div>
                                </Link>
                              

                              
                                <Link
                                  to={`/events/${eventId}/add-event-faq`}
                                  className="dftxt activetxt"
                                >
                                  <div className="evntovr  activecnt"> 
                                  Event FAQS</div>
                                </Link>
                              
                            </div>
                          </div>
                          <div className="col-md-9">



                            <form onSubmit={handleAddFaq}>
                              <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                                  <div className="form-group">
                                    <label>FAQ Question</label>
                                    <input
                                      type="text"
                                      className="form-control inputgroup2"
                                      value={question}
                                      onChange={(e) =>
                                        setQuestion(e.target.value)
                                      }
                                      placeholder="Enter FAQ question"
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                                  <div className="form-group">
                                    <label>FAQ Answer</label>
                                    <textarea
                                      className="form-control textarea1"
                                      rows="5"
                                      value={answer}
                                      onChange={(e) =>
                                        setAnswer(e.target.value)
                                      }
                                      placeholder="Enter FAQ answer"
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-12 col-md-12 mb-2 mt-3 text-end">
                                  <button
                                    type="submit"
                                    className="  btnn org-btn text-light w-25"
                                    disabled={loading}
                                  >
                                    {loading ? "Submitting..." : "Add FAQ's"}
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>

                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


<div className=" mt-3">
<div className="headingcard align-content-center gradient-border py-1 mb-2">
                  <h1 className="text-start text-white px-3 titleh1">
                  All Event FAQ
                  </h1>
                  <p className="text-start text-white px-3 pb-0 ">
                  Below are the FAQs for the event.
                  </p>
                </div>
</div>

                <div className="col-12 card p-2 mt-2">
                            <AllFaqList />
                          </div>


              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddEventFaq;
