import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axiosInstance from '../../axiosInstance'; // Adjust the import based on your project structure
import { toast, ToastContainer } from 'react-toastify'; // Import react-toastify
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for react-toastify
import { useEvent } from '../../contexts/EventContext';

const AddPartnerForm = ({ handleClose }) => {
  const { eventId } = useEvent(); // Get eventId from context
  const [formData, setFormData] = useState({
    event_partner_name: '',
    event_partner_type: '',
    event_partner_image: null
  });

  const [imagePreview, setImagePreview] = useState(null);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: name === 'event_partner_image' ? files[0] : value
    }));

    if (name === 'event_partner_image' && files[0]) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(files[0]);
    }
  };

  const handleButtonClick = () => {
    document.getElementById('imageUploadInput').click();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate required fields
    const requiredFields = ['event_partner_name', 'event_partner_type'];
    const missingFields = requiredFields.filter(field => !formData[field]);

    if (missingFields.length > 0) {
      missingFields.forEach(field => {
        toast.error(`${capitalizeFirstLetter(field.replace(/_/g, ' '))} is required.`);
      });
      return; // Stop form submission if there are missing fields
    }

    // Debugging: Check if eventId is correctly retrieved
    console.log('Event ID:', eventId);

    const data = new FormData();
    data.append('event_unique_id', eventId); // Ensure event_unique_id is appended
    data.append('event_partner_name', formData.event_partner_name);
    data.append('event_partner_type', formData.event_partner_type);
    if (formData.event_partner_image) {
      data.append('event_partner_image', formData.event_partner_image);
    }

    try {
      await axiosInstance.post('/add_event_partner', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      toast.success('Partner added successfully!'); // Show success toastr message
      setFormData({
        event_partner_name: '',
        event_partner_type: '',
        event_partner_image: null
      }); // Clear form data
      setImagePreview(null); // Clear image preview
      document.getElementById('imageUploadInput').value = ''; // Clear file input
      if (handleClose) handleClose(); // Ensure handleClose is a function before calling
    } catch (error) {
      console.error('Error adding partner:', error);
      toast.error('Error adding partner. Please try again.'); // Show error toastr message
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div className="container-dash p-0 position-relative">
      <div className="background-overlay"></div>
      <div className="d-flex justify-content-center align-items-center">
        <div className="col-md-12 d-flex align-items-center justify-content-center">
          <div className="container my-3">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <h1 className="h1-cl text-start">Add Partner</h1>
                <div className="card shadow-lg rounded">
                  <div className="card-body p-3">
                    <form onSubmit={handleSubmit} className="text-start">
                      <div className="mb-3">
                        <div className="row">
                          <div className="col-md-3 text-end">
                            <label htmlFor="event_partner_name" className="form-label lable-cl">Name</label>
                          </div>
                          <div className="col-md-9">
                            <input
                              type="text"
                              id="event_partner_name"
                              name="event_partner_name"
                              className="form-control"
                              placeholder="Enter partner's name"
                              value={formData.event_partner_name}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mb-3">
                        <div className="row">
                          <div className="col-md-3 text-end">
                            <label htmlFor="event_partner_type" className="form-label lable-cl">Type</label>
                          </div>
                          <div className="col-md-9">
                            <select
                              id="event_partner_type"
                              name="event_partner_type"
                              className="form-control"
                              value={formData.event_partner_type}
                              onChange={handleInputChange}
                              required
                            >
                              <option value="">Select partner type</option>
                              <option value="Brand Partner">Brand Partner</option>
                              <option value="Media Partner">Media Partner</option>
                              <option value="Marketing Partner">Marketing Partner</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="mb-3">
                        <div className="row">
                          <div className="col-md-3 text-end">
                            <label className="form-label lable-cl">Image</label>
                          </div>
                          <div className="col-md-9">
                            <div className="image-upload-container">
                              <input
                                type="file"
                                id="imageUploadInput"
                                name="event_partner_image"
                                style={{ display: 'none' }}
                                onChange={handleInputChange}
                              />
                              <button
                                type="button"
                                className="btn org-btn text-light w-75"
                                onClick={handleButtonClick}
                              >
                                Upload
                              </button>
                              {imagePreview && (
                                <img
                                  src={imagePreview}
                                  alt="Preview"
                                  className="img-thumbnail mt-2"
                                  style={{ maxWidth: '100%', maxHeight: '100%' }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="text-center">
                        <button type="submit" className="btn org-btn text-light w-25">Submit</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer /> {/* Add ToastContainer to your component tree */}
    </div>
  );
};

export default AddPartnerForm;