import React, { useEffect, useState, useRef } from 'react';
import axiosInstance from '../../axiosInstance';
import { useEvent } from '../../contexts/EventContext';
import { useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const RatingReviewEvent = () => {
  const { eventId } = useEvent(); // Get the eventId from context
  const [reviews, setReviews] = useState([]);
  const [filteredReviews, setFilteredReviews] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedItemsPerPage, setSelectedItemsPerPage] = useState('10');
  const dropdownRef = useRef(null);
  const { event_unique_id } = useParams(); // Use event_unique_id from URL parameters

  useEffect(() => {
    document.title = 'Rating & Reviews'; // Set the page title here
    if (eventId) {
      fetchRatingReviews(); // Fetch rating reviews if eventId is available
    }
  }, [eventId]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const fetchRatingReviews = async () => {
    try {
      const response = await axiosInstance.get(`/fetch_rating_review/${eventId}`);
      setReviews(response.data.data);
      setFilteredReviews(response.data.data);
    } catch (error) {
      console.error('Error fetching rating reviews:', error);
      toast.error('Error fetching rating reviews.');
    }
  };

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = reviews.filter(review =>
      (review.user_name?.toLowerCase().includes(query) || '') ||
      (review.review_text?.toLowerCase().includes(query) || '')
    );
    setFilteredReviews(filtered);
    setCurrentPage(1); // Reset to first page on search
  };
  

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(Number(value));
    setSelectedItemsPerPage(value);
    setCurrentPage(1); // Reset to first page when items per page changes
    setIsDropdownOpen(false); // Close the dropdown after selection
  };

  const getPaginatedData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredReviews.slice(startIndex, endIndex);
  };

  const totalPages = Math.ceil(filteredReviews.length / itemsPerPage);

  return (
    <div className="container-dash p-0 position-relative">
      <div className="background-overlay"></div>
      <div className="d-flex justify-content-center align-items-center">
        <div className="col-md-12 d-flex align-items-center justify-content-center">
          <div className="container my-3">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card shadow-lg rounded">
                  <div className="card-body p-4">
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <h3 className="text-start h3txt">Rating & Reviews</h3>
                    </div>
                    <div className="row mb-4">
                      <div className="col-2">
                        <div className="custom-select-dropdown" ref={dropdownRef}>
                          <div className="input-container" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                            <input
                              type="text"
                              className="form-control"
                              value={selectedItemsPerPage}
                              readOnly
                            />
                            <FontAwesomeIcon icon={faChevronDown} className="dropdown-icon" />
                          </div>
                          {isDropdownOpen && (
                            <div className="dropdown-menu">
                              {[10, 25, 50, 100, 500].map((num) => (
                                <div
                                  key={num}
                                  className="dropdown-item"
                                  onClick={() => handleItemsPerPageChange(num)}
                                >
                                  {num}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-10 d-flex align-items-center">
                        <input
                          type="text"
                          className="form-control me-2"
                          placeholder="Search by name or review"
                          value={searchQuery}
                          onChange={handleSearch}
                        />
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table mt-3">
                        <thead>
                          <tr>
                            <th>User Name</th>
                            <th>Rating</th>
                            <th>Review</th>
                          </tr>
                        </thead>
                        <tbody>
                          {getPaginatedData().length > 0 ? (
                            getPaginatedData().map((review) => (
                              <tr key={review.id}>
                                <td>{review.user_id}</td>
                                <td>{review.rating}</td>
                                <td>{review.review_text}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="3" className="text-center">
                                No reviews found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-4">
                      <div>
                        Showing {Math.min((currentPage - 1) * itemsPerPage + 1, filteredReviews.length)} - {Math.min(currentPage * itemsPerPage, filteredReviews.length)} of {filteredReviews.length} results
                      </div>
                      <div>
                        <ul className="pagination">
                          {Array.from({ length: totalPages }, (_, index) => (
                            <li
                              key={index}
                              className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}
                            >
                              <button
                                className="page-link"
                                onClick={() => handlePageChange(index + 1)}
                              >
                                {index + 1}
                              </button>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default RatingReviewEvent;
