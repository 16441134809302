import React, { useEffect, useState } from "react";
import axiosInstance from '../../axiosInstance';
import { useEvent } from '../../contexts/EventContext'; // Ensure this context provides eventId

const EventAnalyticsRptOne = () => {
  const [ipCounts, setIpCounts] = useState([]); // State to hold IP counts
  const { eventId } = useEvent(); // Get the eventId from context

  useEffect(() => {
    document.title = "IP Counts Report"; // Set page title
    fetchIpCounts(); // Fetch IP counts on component mount
  }, []);

  // Function to fetch IP counts from the API
  const fetchIpCounts = async () => {
    try {
      const response = await axiosInstance.get(`/ip-counts/${eventId}`);

      // Log the full response text
      console.log("Response data:", response.data);

      // Check if response status is ok
      if (response.status !== 200) {
        throw new Error("Network response was not ok");
      }

      const data = response.data; // Use the data from the response

      // Ensure that data is an object with ipCounts before setting it to state
      if (data && Array.isArray(data.ipCounts)) {
        setIpCounts(data.ipCounts); // Update state with fetched IP counts
      } else {
        console.warn("Expected an object with ipCounts array, but received:", data);
        setIpCounts([]); // Set to an empty array if data is not valid
      }
    } catch (error) {
      console.error("Error fetching IP counts:", error);
      setIpCounts([]); // Set to an empty array on error
    }
  };

  return (
    <div className="container-dash p-0 position-relative">
      <div className="background-overlay"></div>
      <div className="d-flex justify-content-center align-items-center">
        <div className="col-md-12 d-flex align-items-center justify-content-center">
          <div className="container my-3">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card shadow-lg rounded">
                  <div className="card-body p-3">
                    <div className="container">
                      <h2 className="text-center mb-4">IP Address Counts</h2>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>IP Address</th>
                            <th>City</th>
                            <th>Region</th>
                            <th>Country</th>
                            <th>Unique Count</th>
                            <th>Total Count</th>
                          </tr>
                        </thead>
                        <tbody>
                          {ipCounts.length > 0 ? (
                            ipCounts.map((ipCount) => (
                              <tr key={ipCount.ip_address}>
                                <td>{ipCount.ip_address}</td>
                                <td>{ipCount.city}</td>
                                <td>{ipCount.region}</td>
                                <td>{ipCount.country}</td>
                                <td>{ipCount.unique_count}</td>
                                <td>{ipCount.total_count}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="6" className="text-center">No data available</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventAnalyticsRptOne;
