import React, { useEffect, useState } from "react";
import { useEvent } from "../../contexts/EventContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axiosInstance from "../../axiosInstance"; // Import the Axios instance
import { ToastContainer, toast } from "react-toastify"; // Import react-toastify
import "react-toastify/dist/ReactToastify.css"; // Import CSS for react-toastify
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import { Link } from "react-router-dom";
import OrgTopBar from "../OrgTopBar";

const EventOverview2 = () => {
  const { eventId } = useEvent();
  const [category, setCategory] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [frequency, setFrequency] = useState("");
  const [eventName, setEventName] = useState("");
  const [eventType, setEventType] = useState("");
  const [eventMode, setEventMode] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  // Industry list
  const categories = ["Technology", "Health", "Education", "Science"];

  const handleCategoryClick = (selectedCategory) => {
    const categoryArray = category.split(",").map((cat) => cat.trim());

    if (categoryArray.includes(selectedCategory)) {
      const updatedCategory = categoryArray
        .filter((cat) => cat !== selectedCategory)
        .join(", ");
      setCategory(updatedCategory);
    } else {
      setCategory(
        category ? `${category}, ${selectedCategory}` : selectedCategory
      );
    }
  };

  useEffect(() => {
    document.title = "Event Overview";

    const fetchEventDetails = async () => {
      setLoading(true);
      setError(null);

      if (!eventId) {
        setError("Invalid Event ID");
        setLoading(false);
        return;
      }

      try {
        const response = await axiosInstance.get(
          `/fetcheventByUniqueId/${eventId}`
        );
        const event = response.data;

        if (!event) {
          setError("Event not found");
        } else {
          setEventName(event.event_name);
          setEventType(event.event_type);
          setEventMode(event.event_mode);
          setCategory(event.event_industry);

          const start = new Date(event.event_start_date);
          const end = new Date(event.event_end_date);

          setStartDate(isNaN(start.getTime()) ? null : start);
          setEndDate(isNaN(end.getTime()) ? null : end);
          setFrequency(event.event_frequency);
        }
      } catch (error) {
        console.error(
          "Error fetching event details:",
          error.response ? error.response.data : error.message
        );
        setError("An error occurred while fetching the event details.");
      } finally {
        setLoading(false);
      }
    };

    fetchEventDetails();
  }, [eventId]);

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    if (endDate && date > endDate) {
      setEndDate(null); // Reset end date if start date exceeds it
      toast.error("Please select an end date after the start date.");
    }
  };

  const handleEndDateChange = (date) => {
    if (!startDate) {
      toast.error("Please select the start date first.");
      return;
    }
    if (date < startDate) {
      toast.error("End date cannot be before the start date.");
      return;
    }
    setEndDate(date);
  };

  const handleFrequencyChange = (e) => {
    setFrequency(e.target.value);
  };

  const handleUpdateEvent = async (e) => {
    e.preventDefault();

    // Create a new date and increment by 1 day to offset the timezone issue
    const adjustedStartDate = new Date(startDate);
    adjustedStartDate.setDate(adjustedStartDate.getDate() + 1);

    const adjustedEndDate = new Date(endDate);
    adjustedEndDate.setDate(adjustedEndDate.getDate() + 1);

    try {
      const response = await axiosInstance.put(
        `/updateEventFormOne/${eventId}`,
        {
          event_name: eventName,
          event_type: eventType,
          event_mode: eventMode,
          event_category: category,
          start_date: adjustedStartDate, // Use the adjusted start date
          end_date: adjustedEndDate, // Use the adjusted end date
          frequency,
        }
      );
      toast.success("Event updated successfully!");
    } catch (error) {
      console.error(
        "Error updating event details:",
        error.response ? error.response.data : error.message
      );
      toast.error("Error updating event details.");
    }
  };

  if (loading) return <p>Loading...</p>;

  return (
    <div className="container-dash p-0 position-relative">
      <OrgTopBar />
      <div className="background-overlay"></div>
      <div className="d-flex justify-content-center align-items-center">
        <div className="col-md-12 d-flex align-items-center justify-content-center">
          <div className="container my-3">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div class="headingcard align-content-center gradient-border py-1 mb-2">
                  <h1 class="text-start text-white px-3 titleh1">
                    Event Overview
                  </h1>
                  <p class="text-start text-white px-3 pb-0 ">
                    Overview of Event
                  </p>
                </div>

                <div className="card shadow-lg rounded">
                  <div className="card-body p-3">
                    <div className="tab-content" id="myTabContent">
                      <div
                        className="tab-pane fade show active w-100"
                        id="home"
                        role="tabpanel"
                      >
                        {error ? (
                          <p className="text-danger">{error}</p>
                        ) : (
                          <form onSubmit={handleUpdateEvent}>
                            <div className="row text-start">
                              <div className="col-md-3">
                                <div className="card p-2">
                                  <Link
                                    to={`/events/${eventId}`}
                                    className=" activetxt dftxt"
                                  >
                                    <div className="evntovr activecnt">
                                      Event Basic Details{" "}
                                    </div>
                                  </Link>

                                  <Link
                                    to={`/events/${eventId}/EventTimeZone`}
                                    className="  dftxt"
                                  >
                                    <div className="evntovr">
                                      Event Time Details
                                    </div>
                                  </Link>

                                  <Link
                                    to={`/events/${eventId}/eventvenue`}
                                    className="  dftxt"
                                  >
                                    <div className="evntovr">
                                      Event Venue Details
                                    </div>
                                  </Link>

                                  <Link
                                    to={`/events/${eventId}/add-event-faq`}
                                    className="dftxt "
                                  >
                                    <div className="evntovr  ">Event FAQS</div>
                                  </Link>
                                </div>
                              </div>
                              <div className="col-md-9">
                                <div className="row">
                                  <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                                    <div className="form-group">
                                      <label>Event Name</label>
                                      <input
                                        type="text"
                                        className="form-control inputgroup2"
                                        value={eventName}
                                        onChange={(e) =>
                                          setEventName(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className="col-lg-6 col-md-6 col-sm-12 mb-2">
                                    <div className="form-group">
                                      <label>Event Type</label>
                                      <input
                                        type="text"
                                        className="form-control inputgroup2"
                                        value={eventType}
                                        onChange={(e) =>
                                          setEventType(e.target.value)
                                        }
                                        readOnly
                                      />
                                    </div>
                                  </div>

                                  <div className="col-lg-6 col-md-6 col-sm-12 mb-2">
                                    <div className="form-group">
                                      <label>Event Mode</label>
                                      <input
                                        type="text"
                                        className="form-control inputgroup2"
                                        value={eventMode}
                                        onChange={(e) =>
                                          setEventMode(e.target.value)
                                        }
                                        readOnly
                                      />
                                    </div>
                                  </div>

                                  <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                                    <div className="form-group">
                                      <label>Event Category</label>
                                      <textarea
                                        className="form-control textarea1"
                                        rows="3"
                                        value={category}
                                        onChange={handleCategoryChange}
                                      />

                                      <div className="mt-2 txt-spn">
                                        {categories.map((cat) => (
                                          <span
                                            key={cat}
                                            className={`example-category text-dark okp ${
                                              category.includes(cat)
                                                ? "text-decoration-line-through"
                                                : ""
                                            }`}
                                            onClick={() =>
                                              handleCategoryClick(cat)
                                            }
                                            style={{
                                              cursor: "pointer",
                                              marginRight: "10px",
                                            }}
                                          >
                                            {cat}
                                          </span>
                                        ))}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-lg-4 col-md-4 mb-2">
                                    <div className="form-group">
                                      <label>Start Date</label>
                                      <DatePicker
                                        selected={startDate}
                                        onChange={handleStartDateChange}
                                        className="form-control inputgroup2"
                                        minDate={new Date()}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-4 col-md-4 mb-2">
                                    <div className="form-group">
                                      <label>End Date</label>
                                      <DatePicker
                                        selected={endDate}
                                        onChange={handleEndDateChange}
                                        className="form-control inputgroup2"
                                      />
                                    </div>
                                  </div>

                                  <div className="col-lg-4 col-md-4 mb-2">
                                    <div className="form-group">
                                      <label>Frequency</label>
                                      <select
                                        className="form-control inputgroup2"
                                        value={frequency}
                                        onChange={handleFrequencyChange}
                                      >
                                        <option value="One Time Event">
                                          One Time Event
                                        </option>
                                        <option value="Every Week">
                                          Every Week
                                        </option>
                                        <option value="Every Month">
                                          Every Month
                                        </option>
                                        <option value="Once In 3 Month">
                                          Once In 3 Month
                                        </option>
                                        <option value="Once In 6 Month">
                                          Once In 6 Month
                                        </option>
                                        <option value="Once In a year">
                                          Once In a year
                                        </option>
                                        <option value="Once In 2 years">
                                          Once In 2 years
                                        </option>
                                        <option value="Once In 3 years">
                                          Once In 3 years
                                        </option>
                                        <option value="Once In 4 years">
                                          Once In 4 years
                                        </option>
                                        <option value="Once In 5 years">
                                          Once In 5 years
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-12 col-md-12 mb-2 mt-3 text-end">
                                  <button
                                    type="submit"
                                    className="btn btnn org-btn text-light w-25"
                                  >
                                    Update Event
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default EventOverview2;
