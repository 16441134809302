import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom'; // Import Navigate for redirection
import { toast } from 'react-toastify'; // Import toast for notifications
import 'react-toastify/dist/ReactToastify.css'; // Import toast CSS

const Logout = () => {
  const [redirectToLogin, setRedirectToLogin] = useState(false);

  useEffect(() => {
    // Clear specific local storage items
    localStorage.removeItem('random_token');
    localStorage.removeItem('organiser_id');

    // Optionally show a toast notification
    toast.success('Logged out successfully!');

    // Trigger redirection to the login page
    setRedirectToLogin(true);
  }, []);

  // Redirect to login if the redirect state is true
  if (redirectToLogin) {
    return <Navigate to="/login" />;
  }

  return (
    <div>
      {/* Optionally add a loading spinner or message */}
      <p>Logging out...</p>
    </div>
  );
};

export default Logout;
