import React, { useEffect, useState, useRef } from "react";
import axiosInstance from "../../axiosInstance";
import { useEvent } from "../../contexts/EventContext";
import { Link, useParams } from "react-router-dom";
import { saveAs } from "file-saver"; // For downloading Excel files
import * as XLSX from "xlsx"; // For creating Excel files
import { toast, ToastContainer } from "react-toastify"; // Import react-toastify
import "react-toastify/dist/ReactToastify.css"; // Import react-toastify styles
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"; 
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
const EventExhibitor = () => {
  const { eventId } = useEvent(); // Get the eventId from context
  const [exhibitors, setExhibitors] = useState([]);
  const [filteredExhibitors, setFilteredExhibitors] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedItemsPerPage, setSelectedItemsPerPage] = useState("10");
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const dropdownRef = useRef(null);
  const { event_unique_id } = useParams();

  useEffect(() => {
    document.title = "Exhibitors Details"; // Set the page title here
    if (eventId) {
      fetchExhibitors(); // Fetch exhibitors if eventId is available
    }
  }, [eventId]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const fetchExhibitors = async () => {
    try {
      const response = await axiosInstance.get(`/fetch_exhibitors/${eventId}`);
      setExhibitors(response.data.data);
      setFilteredExhibitors(response.data.data);
    } catch (error) {
      console.error("Error fetching exhibitors:", error);
    }
  };

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = exhibitors.filter(
      (exhibitor) =>
        exhibitor.event_exhibitor_name.toLowerCase().includes(query) ||
        exhibitor.event_exhibitor_company.toLowerCase().includes(query)
    );
    setFilteredExhibitors(filtered);
    setCurrentPage(1); // Reset to first page on search
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(Number(value));
    setSelectedItemsPerPage(value);
    setCurrentPage(1); // Reset to first page when items per page changes
    setIsDropdownOpen(false); // Close the dropdown after selection
  };

  const getPaginatedData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredExhibitors.slice(startIndex, endIndex);
  };

  const totalPages = Math.ceil(filteredExhibitors.length / itemsPerPage);

  const handleCheckboxChange = (id) => {
    setSelectedRows((prevState) =>
      prevState.includes(id)
        ? prevState.filter((item) => item !== id)
        : [...prevState, id]
    );
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      setSelectedRows(filteredExhibitors.map((exhibitor) => exhibitor.id));
    }
    setSelectAll(!selectAll);
  };

  const handleExport = () => {
    const selectedData = exhibitors.filter((exhibitor) =>
      selectedRows.includes(exhibitor.id)
    );

    if (selectedData.length === 0) {
      toast.warning("No rows selected for export."); // Use Toastr for showing warning message
      return;
    }

    // Filter to include only specific fields
    const exportData = selectedData.map(
      ({
        event_exhibitor_name,
        event_exhibitor_designation,
        event_exhibitor_company,
        event_exhibitor_email,
        event_exhibitor_phone,
        event_exhibitor_booth_no,
      }) => ({
        Name: event_exhibitor_name,
        Designation: event_exhibitor_designation,
        Company: event_exhibitor_company,
        Email: event_exhibitor_email,
        Phone: event_exhibitor_phone,
        "Booth Number": event_exhibitor_booth_no,
      })
    );

    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Exhibitors");
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    saveAs(
      new Blob([wbout], { type: "application/octet-stream" }),
      "exhibitors.xlsx"
    );
  };

  return (
    <div className="container-dash p-0 position-relative">
      <div className="background-overlay"></div>
      <div className="d-flex justify-content-center align-items-center">
        <div className="col-md-12 d-flex align-items-center justify-content-center">
          <div className="container my-3">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12 col-sm-12">

              <div class="headingcard align-content-center gradient-border py-1 mb-2">
                  <h1 class="text-start text-white px-3 titleh1">
                  Exhibitors Details
                  </h1>
                  <p class="text-start text-white px-3 pb-0 ">
                  Below is the list of added exhibitor list.
                  </p>
                </div>


                <div className="card shadow-lg rounded">
                  <div className="card-body p-4">


                  <div className=" text-end mb-2">
                  
                  <Link
                    to={`/events/${event_unique_id}/Add-Exhibitors`}
                    className="text-light shotbtn p-2 bg-gradient-info"
                  >
                    Add Exhibitors
                  </Link>
                </div>

                    {/* <div className="d-flex justify-content-between align-items-center mb-4">
                     
                      <Link
                        to={`/events/${event_unique_id}/Add-Exhibitors`} // Link to the Add Exhibitors page
                        className="btn btn-primary"
                      >
                        Add Exhibitors
                      </Link>
                    </div> */}
                    <div className="row mb-4">
                      <div className="col-2">
                        <div
                          className="custom-select-dropdown"
                          ref={dropdownRef}
                        >
                          <div
                            className="input-container"
                            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                          >
                            <input
                              type="text"
                              className="form-control"
                              value={selectedItemsPerPage}
                              readOnly
                            />
                            <FontAwesomeIcon
                              icon={faChevronDown}
                              className="dropdown-icon"
                            />
                          </div>
                          {isDropdownOpen && (
                            <div className="dropdown-menu">
                              {[10, 25, 50, 100, 500].map((num) => (
                                <div
                                  key={num}
                                  className="dropdown-item"
                                  onClick={() => handleItemsPerPageChange(num)}
                                >
                                  {num}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-10 d-flex align-items-center">
                        <input
                          type="text"
                          className="form-control me-2"
                          placeholder="Search by name or company"
                          value={searchQuery}
                          onChange={handleSearch}
                        />
                        <button
                          className="btn btn-success ms-2"
                          onClick={handleExport}
                        >
                          Export
                        </button>
                      </div>
                    </div>

                    <div>
                      <div class="row text-start ft-head-table py-2 mt-2 mb-2">
                        <div class="col">
                          <input
                            type="checkbox"
                            checked={selectAll}
                            onChange={handleSelectAllChange}
                          />
                        </div>
                        <div class="col">Name</div>
                        <div class="col">Designation</div>
                        <div class="col">Company</div>
                        <div class="col">Email</div>
                        <div class="col">Phone</div>
                        <div class="col">Booth No</div>
                        <div class="col">Image</div>
                        <div class="col text-end">Action</div>
                      </div>

                      {getPaginatedData().length > 0 ? (
                        getPaginatedData().map((exhibitor) => (
                          <div
                            class="row py-2 ft-table text-start"
                            key={exhibitor.id}
                          >
                            <div class="col">
                              <input
                                type="checkbox"
                                checked={selectedRows.includes(exhibitor.id)}
                                onChange={() =>
                                  handleCheckboxChange(exhibitor.id)
                                }
                              />
                            </div>

                            <div class="col">
                              {exhibitor.event_exhibitor_name}
                            </div>
                            <div class="col">
                              {exhibitor.event_exhibitor_designation}
                            </div>
                            <div class="col">
                              {exhibitor.event_exhibitor_company}
                            </div>
                            <div class="col">
                              {exhibitor.event_exhibitor_email}
                            </div>
                            <div class="col">
                              {exhibitor.event_exhibitor_phone}
                            </div>
                            <div class="col">
                              {exhibitor.event_exhibitor_booth_no}
                            </div>
                            <div class="col">
                              {exhibitor.event_exhibitor_image ? (
                                <img
                                  src={exhibitor.event_exhibitor_image}
                                  alt={exhibitor.event_exhibitor_name}
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    objectFit: "cover",
                                  }}
                                />
                              ) : (
                                <span className="text-dark">No Image</span>
                              )}
                            </div>

                            <div class="col text-end">

                            <span className="nav_icondelete text-center w-50 px-3">
                            <Link
                              className="text-white"
                               
                            >
                              <FontAwesomeIcon icon={faTrashAlt} />
                            </Link>
                            </span>

                        

                            </div>
                          </div>
                        ))
                      ) : (
                        <p className="text-center">No Exhibitors Found</p>
                      )}
                    </div>

                    <div className="table-responsive">
                      <table className="table mt-3">
                        <tbody></tbody>
                      </table>
                      <div className="d-flex justify-content-between align-items-center mt-3">
                        <div>
                          Showing{" "}
                          {Math.min(
                            (currentPage - 1) * itemsPerPage + 1,
                            filteredExhibitors.length
                          )}{" "}
                          to{" "}
                          {Math.min(
                            currentPage * itemsPerPage,
                            filteredExhibitors.length
                          )}{" "}
                          of {filteredExhibitors.length} entries
                        </div>
                        <div>
                          <ul className="pagination">
                            <li className="page-item">
                              <button
                                className="page-link"
                                onClick={() =>
                                  handlePageChange(currentPage - 1)
                                }
                                disabled={currentPage === 1}
                              >
                                Previous
                              </button>
                            </li>
                            {[...Array(totalPages).keys()].map((pageNumber) => (
                              <li
                                key={pageNumber + 1}
                                className={`page-item ${
                                  currentPage === pageNumber + 1 ? "active" : ""
                                }`}
                              >
                                <button
                                  className="page-link"
                                  onClick={() =>
                                    handlePageChange(pageNumber + 1)
                                  }
                                >
                                  {pageNumber + 1}
                                </button>
                              </li>
                            ))}
                            <li className="page-item">
                              <button
                                className="page-link"
                                onClick={() =>
                                  handlePageChange(currentPage + 1)
                                }
                                disabled={currentPage === totalPages}
                              >
                                Next
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default EventExhibitor;
