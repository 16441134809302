import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../axiosInstance';
import { useEvent } from '../../contexts/EventContext';
import * as XLSX from 'xlsx'; // Import XLSX for Excel export
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { toast, ToastContainer } from 'react-toastify';  
import 'react-toastify/dist/ReactToastify.css';  

const SponserLeads = () => {
  const [responses, setResponses] = useState([]);
  const [fields, setFields] = useState({});
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const { form_for = 'Sponser Form' } = useParams(); // Set default value for form_for
  const { eventId } = useEvent();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedItemsPerPage, setSelectedItemsPerPage] = useState(10); // Set default items per page
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Dropdown visibility control
  const [isExportDropdownOpen, setIsExportDropdownOpen] = useState(false); // Export dropdown control
  const [selectedColumns, setSelectedColumns] = useState([]); // Columns selected for export
  const [includeCreatedAt, setIncludeCreatedAt] = useState(false); // Include Created At in export
  const [loading, setLoading] = useState(false); // Loader state
  const dropdownRef = useRef(null);

  // Log eventId to check its value
  console.log('Event ID from context:', eventId);

  const fetchResponses = useCallback(async () => {
    if (!eventId) {
      console.error('Event ID is not available.');
      setError('Event ID is not available.');
      return;
    }

    setLoading(true); // Show loader

    try {
      console.log('Fetching responses for eventId:', eventId, 'form_for:', form_for);

      const response = await axiosInstance.get(`/fetch_responses/${eventId}/${form_for}`);
      const data = response.data;

      if (!data.data || !Array.isArray(data.data)) {
        console.error('Invalid data format');
        setError('Invalid data format');
        return;
      }

      console.log('Fetched data:', data.data); // Log the data being set

      setFields(data.fields || {});
      setResponses(data.data);
      setSelectedColumns(Object.keys(data.fields)); // Initially select all columns
      setIncludeCreatedAt(true); // Default to include Created At
      setError(null); // Clear previous errors

    } catch (error) {
      console.error('Error fetching responses:', error.message);
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
        setError(error.response.data.error || 'An error occurred');
      } else {
        setError('An error occurred');
      }
    } finally {
      setLoading(false); // Hide loader
    }
  }, [eventId, form_for]);

  useEffect(() => {
    fetchResponses();
  }, [fetchResponses]);

  // Handle search logic
  const filteredResponses = responses.filter((response) =>
    Object.keys(response).some((key) =>
      String(response[key]).toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  // Handle checkbox selection logic
  const toggleRowSelection = (rowId) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(rowId)
        ? prevSelected.filter((id) => id !== rowId)
        : [...prevSelected, rowId]
    );
  };

  const toggleSelectAll = (isChecked) => {
    setSelectedRows(isChecked ? filteredResponses.map((row) => row.response_id) : []);
  };

  // Handle items per page change
  const handleItemsPerPageChange = (num) => {
    setSelectedItemsPerPage(num);
    setIsDropdownOpen(false);
    setCurrentPage(1); // Reset to the first page when items per page changes
  };

  // Handle column selection for export
  const toggleColumnSelection = (column) => {
    setSelectedColumns((prevSelected) =>
      prevSelected.includes(column)
        ? prevSelected.filter((col) => col !== column)
        : [...prevSelected, column]
    );
  };

  // Pagination logic
  const indexOfLastRow = currentPage * selectedItemsPerPage;
  const indexOfFirstRow = indexOfLastRow - selectedItemsPerPage;
  const currentRows = filteredResponses.slice(indexOfFirstRow, indexOfLastRow);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Handle Excel Download
  const downloadExcel = () => {
    toast.success('Exporting your data...');

    const selectedData = responses.filter((response) =>
      selectedRows.includes(response.response_id)
    );

    const excelData = selectedData.map((row) => {
      const formattedRow = {};
      selectedColumns.forEach((fieldId) => {
        formattedRow[fields[fieldId]] = row[fieldId] || 'N/A';
      });
      // Add created_at field to the export if selected
      if (includeCreatedAt) {
        formattedRow['Created At'] = row.created_at 
          ? new Date(row.created_at).toLocaleDateString('en-GB', {
              day: '2-digit',
              month: 'short',
              year: '2-digit'
            }) + ' ' +
            new Date(row.created_at).toLocaleTimeString('en-GB', {
              hour: '2-digit',
              minute: '2-digit',
              hour12: true
            }) 
          : 'N/A';
      }
      return formattedRow;
    });

    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Selected Data');

    XLSX.writeFile(workbook, `${form_for}-selected-columns-responses.xlsx`);
    setIsExportDropdownOpen(false); // Close the export dropdown after download
  };

  return (
    <div className="container-dash p-0 position-relative">
      <div className="background-overlay"></div>
      <div className="d-flex justify-content-center align-items-center">
        <div className="col-md-12 d-flex align-items-center justify-content-center">
          <div className="container my-3">
            <div className="bg-light p-2">
              <h3 className="text-start h3txt">Sponser Leads</h3>
              <p className="text-body-tertiary lh-sm mb-2 p1txt">All Sponser Form Data is Specified Below</p>
              <hr />

              {/* Show loader if loading */}
              {loading && <div className="d-flex justify-content-center my-4"><div className="loader"></div></div>}

              {/* Search box and export button with column selection */}
              <div className="row mb-4">
                {/* Items per page dropdown */}
                <div className="col-2">
                  <div className="custom-select-dropdown" ref={dropdownRef}>
                    <div
                      className="input-container"
                      onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    >
                      <input
                        type="text"
                        className="form-control"
                        value={selectedItemsPerPage}
                        readOnly
                      />
                      <FontAwesomeIcon icon={faChevronDown} className="dropdown-icon" />
                    </div>
                    {isDropdownOpen && (
                      <div className="dropdown-menu">
                        {[10, 25, 50, 100, 500].map((num) => (
                          <div
                            key={num}
                            className="dropdown-item"
                            onClick={() => handleItemsPerPageChange(num)}
                          >
                            {num}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                {/* Search box and export button with column selection */}
                <div className="col-10 d-flex align-items-center">
                  <input
                    type="text"
                    className="form-control me-2"
                    placeholder="Search by text.."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />

                  {/* Export button with dropdown for column selection */}
                  <div className="position-relative">
                    <button
                      className="btn btn-success ms-2"
                      onClick={() => setIsExportDropdownOpen(!isExportDropdownOpen)}
                    >
                      Export
                    </button>

                    {isExportDropdownOpen && (
                      <div className="dropdown-menu show position-absolute mydrop">
                        {Object.keys(fields).map((fieldId) => (
                          <div key={fieldId} className="dropdown-item">
                            <input
                              type="checkbox"
                              checked={selectedColumns.includes(fieldId)}
                              onChange={() => toggleColumnSelection(fieldId)}
                            />
                            {fields[fieldId]}
                          </div>
                        ))}
                        <div className="dropdown-item">
                          <input
                            type="checkbox"
                            checked={includeCreatedAt}
                            onChange={() => setIncludeCreatedAt(!includeCreatedAt)}
                          />
                          Include Created At
                        </div>
                        <div className="text-center">
                        <button
                          className="btn btn-success mt-2"
                          onClick={downloadExcel}
                        >
                          Download Excel
                        </button>
                        </div>
                        
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Table with data */}
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>
                      <input
                        type="checkbox"
                        onChange={(e) => toggleSelectAll(e.target.checked)}
                      />
                    </th>
                    {Object.values(fields).map((field) => (
                      <th key={field}>{field}</th>
                    ))}
                    {includeCreatedAt && <th>Created At</th>}
                  </tr>
                </thead>
                <tbody>
  {filteredResponses.length === 0 ? (
    <tr>
      <td colSpan={Object.keys(fields).length + (includeCreatedAt ? 1 : 0)} className="text-center">
        <div className="no-records-container">
          <div className="empty-tray"></div>
          <div className="no-records-message">No records found</div>
        </div>
      </td>
    </tr>
  ) : (
    currentRows.map((row) => (
      <tr key={row.response_id}>
        <td>
          <input
            type="checkbox"
            checked={selectedRows.includes(row.response_id)}
            onChange={() => toggleRowSelection(row.response_id)}
          />
        </td>
        {Object.keys(fields).map((fieldId) => (
          <td key={fieldId}>{row[fieldId] || 'N/A'}</td>
        ))}
        {includeCreatedAt && <td>{row.created_at ? new Date(row.created_at).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: '2-digit' }) + ' ' + new Date(row.created_at).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', hour12: true }) : 'N/A'}</td>}
      </tr>
    ))
  )}
</tbody>
              </table>

              {/* Pagination */}
              <div>
                  <ul className="pagination">
                    {Array.from({ length: Math.ceil(filteredResponses.length / selectedItemsPerPage) }).map(
                      (_, index) => (
                        <li
                          key={index}
                          className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}
                        >
                          <button className="page-link" onClick={() => paginate(index + 1)}>
                            {index + 1}
                          </button>
                        </li>
                      )
                    )}
                  </ul>
                </div>

            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default SponserLeads;
