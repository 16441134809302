import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axiosInstance from '../../axiosInstance'; // Adjust the import based on your project structure
import { toast, ToastContainer } from 'react-toastify'; // Import react-toastify
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for react-toastify
import { useEvent } from '../../contexts/EventContext';

const AddSpeakersForm = ({ handleClose }) => {
  const { eventId } = useEvent(); // Get eventId from context
  const [formData, setFormData] = useState({
    name: '',
    designation: '',
    company: '',
    email: '',
    phone: '',
    image: null
  });

  const [imagePreview, setImagePreview] = useState(null);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: name === 'image' ? files[0] : value
    }));

    // Handle image preview
    if (name === 'image' && files[0]) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(files[0]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate required fields
    const requiredFields = ['name', 'designation', 'company', 'email', 'phone'];
    const missingFields = requiredFields.filter(field => !formData[field]);

    if (missingFields.length > 0) {
      missingFields.forEach(field => {
        toast.error(`${capitalizeFirstLetter(field)} is required.`);
      });
      return; // Stop form submission if there are missing fields
    }

    const data = new FormData();
    data.append('eventId', eventId); // Ensure eventId is appended
    data.append('name', formData.name);
    data.append('designation', formData.designation);
    data.append('company', formData.company);
    data.append('email', formData.email);
    data.append('phone', formData.phone); 
    if (formData.image) {
      data.append('image', formData.image);
    }

    try {
      await axiosInstance.post('/add_event_speaker', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      toast.success('Speaker added successfully!'); // Show success toastr message
      setFormData({
        name: '',
        designation: '',
        company: '',
        email: '',
        phone: '', 
        image: null
      }); // Clear form data
      setImagePreview(null); // Clear image preview
      if (handleClose) handleClose(); // Ensure handleClose is a function before calling
    } catch (error) {
      console.error('Error adding speaker:', error);
      toast.error('Error adding speaker. Please try again.'); // Show error toastr message
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div className="container-dash p-0 position-relative">
      <div className="background-overlay"></div>
      <div className="d-flex justify-content-center align-items-center">
        <div className="col-md-12 d-flex align-items-center justify-content-center">
          <div className="container my-3">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card shadow-lg rounded">
                  <div className="card-body p-3">
                    <h1 className="h1-cl text-start">Add Speaker</h1>
                    <form onSubmit={handleSubmit} className="text-start">
                      <div className="mb-3">
                        <div className="row">
                          <div className="col-md-3 text-end">
                            <label htmlFor="speakerName" className="form-label lable-cl">Name</label>
                          </div>
                          <div className="col-md-9">
                            <input
                              type="text"
                              id="speakerName"
                              name="name"
                              className="form-control"
                              placeholder="Enter Speaker's name"
                              value={formData.name}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mb-3">
                        <div className="row">
                          <div className="col-md-3 text-end">
                            <label htmlFor="speakerDesignation" className="form-label lable-cl">Designation</label>
                          </div>
                          <div className="col-md-9">
                            <input
                              type="text"
                              id="speakerDesignation"
                              name="designation"
                              className="form-control"
                              placeholder="Enter Designation"
                              value={formData.designation}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mb-3">
                        <div className="row">
                          <div className="col-md-3 text-end">
                            <label htmlFor="speakerCompany" className="form-label lable-cl">Company</label>
                          </div>
                          <div className="col-md-9">
                            <input
                              type="text"
                              id="speakerCompany"
                              name="company"
                              className="form-control"
                              placeholder="Enter Company name"
                              value={formData.company}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mb-3">
                        <div className="row">
                          <div className="col-md-3 text-end">
                            <label htmlFor="speakerEmail" className="form-label lable-cl">Email</label>
                          </div>
                          <div className="col-md-9">
                            <input
                              type="email"
                              id="speakerEmail"
                              name="email"
                              className="form-control"
                              placeholder="Enter Email"
                              value={formData.email}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mb-3">
                        <div className="row">
                          <div className="col-md-3 text-end">
                            <label htmlFor="speakerPhone" className="form-label lable-cl">Phone</label>
                          </div>
                          <div className="col-md-9">
                            <input
                              type="text"
                              id="speakerPhone"
                              name="phone"
                              className="form-control"
                              placeholder="Enter Phone Number"
                              value={formData.phone}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mb-3">
                        <div className="row">
                          <div className="col-md-3 text-end">
                            <label className="form-label lable-cl">Image</label>
                          </div>
                          <div className="col-md-9">
                            <input
                              type="file"
                              id="speakerImage"
                              name="image"
                              className="form-control"
                              accept="image/*"
                              onChange={handleChange}
                            />
                            {imagePreview && (
                              <img
                                src={imagePreview}
                                alt="Preview"
                                className="img-thumbnail mt-2"
                                style={{ maxWidth: '100%', maxHeight: '100%' }}
                              />
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="text-center">
                        <button type="submit" className="btn org-btn text-light w-25">Submit</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer /> {/* Include ToastContainer to render toastr messages */}
    </div>
  );
};

export default AddSpeakersForm;